import { Coach } from "../../api/models/coach";
import classes from "./CoachCard.module.scss";
import { useAppSelector } from "../../store/hooks";
import AdminOptions from "../admin-options/AdminOptions";
import ImagePlaceholder from "../image-placeholder/ImagePlaceholder";
import { ColorScheme } from "../footer/Footer";
import Icons, { Icon } from "../icons/Icons";
import { useDispatch } from "react-redux";
import { adminActions } from "../../store/admin-slice";
import AdminModals from "../admin-modals/AdminModals";
import { AdminModalType } from "../admin-modals/admin-modal-type";
import { useNavigate } from "react-router-dom";

interface CoachCardProps {
  data: Coach;
}
const CoachCard = (props: CoachCardProps) => {
  const isAdmin = useAppSelector((store) => store.admin.isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coachClickHandler = () => {
    navigate(`/sluzby?coaches=${props.data.coach_id}`);
  };
  const onEditHandler = () => {
    const modalData = props.data;
    delete modalData.created_at;
    dispatch(
      adminActions.openModal({
        modalType: AdminModalType.EDIT_COACH,
        modalData,
      })
    );
  };
  const onDeleteHandler = () => {
    dispatch(
      adminActions.openModal({
        modalType: AdminModalType.DELETE_MODAL,
        modalData: {
          id: props.data.coach_id,
          type: AdminModalType.DELETE_COACH,
          description: `Naozaj chcete vymazať trenéra: ${props.data.name}?`,
        },
      })
    );
  };

  const coachImage = props.data.coach_image_url ? (
    <img
      src={props.data.coach_image_url}
      alt={`${props.data.name} profile pic`}
    />
  ) : (
    <ImagePlaceholder type={ColorScheme.DARK} />
  );
  return (
    <div className={classes.wrapper}>
      {isAdmin && (
        <AdminOptions
          onEditClick={onEditHandler}
          onDeleteClick={onDeleteHandler}
        />
      )}
      <div className={classes["content-holder"]}>
        <div className={classes["image-wrapper"]} onClick={coachClickHandler}>
          <div className={classes["image-holder"]}>{coachImage}</div>
        </div>
        <div className={classes["info-holder"]}>
          <h2 className={classes.title} onClick={coachClickHandler}>
            {props.data.name}
          </h2>
          <div className={`${classes["description-holder"]} light-scrollbar`}>
            <p className={`${classes.description}`}>{props.data.description}</p>
          </div>
          <div className={classes["socials-row"]}>
            {props.data.socials?.map((item) => {
              switch (item.platform_key) {
                case "fb":
                  return (
                    <a
                      href={item.platform_value}
                      target="_blank"
                      key={item.platform_id}
                    >
                      <Icons style={ColorScheme.DARK} icon={Icon.FB} />
                    </a>
                  );
                case "ig":
                  return (
                    <a
                      href={item.platform_value}
                      target="_blank"
                      key={item.platform_id}
                    >
                      <Icons style={ColorScheme.DARK} icon={Icon.IG} />
                    </a>
                  );
                case "email":
                  return (
                    <a
                      href={`mailto:${item.platform_value}`}
                      target="_blank"
                      key={item.platform_id}
                    >
                      <Icons style={ColorScheme.DARK} icon={Icon.MAIL} />
                    </a>
                  );
                case "phone":
                  return (
                    <a
                      href={`tel:${item.platform_value}`}
                      target="_blank"
                      key={item.platform_id}
                    >
                      <Icons style={ColorScheme.DARK} icon={Icon.PHONE} />
                    </a>
                  );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CoachCard;
