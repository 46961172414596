import { ColorScheme } from "../footer/Footer";
import classes from "./ImagePlaceholder.module.scss";
interface ImagePlaceholderProps {
  type: ColorScheme;
}
const ImagePlaceholder = (props: ImagePlaceholderProps) => {
  if (props.type === ColorScheme.DARK) {
    return (
      <svg
        className={classes.svg}
        width="109"
        height="109"
        viewBox="0 0 109 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M109 5.5045L103.495 0L0 103.495L5.5045 109L13.2902 101.214H93.4286C95.4925 101.211 97.4711 100.39 98.9305 98.9305C100.39 97.4711 101.211 95.4925 101.214 93.4286V13.2902L109 5.5045ZM93.4286 93.4286H21.0759L51.413 63.0915L60.6741 72.3526C62.1341 73.8122 64.1141 74.6322 66.1786 74.6322C68.2431 74.6322 70.223 73.8122 71.6831 72.3526L77.8571 66.1786L93.4286 81.7383V93.4286ZM93.4286 70.7254L83.3616 60.6585C81.9016 59.1989 79.9216 58.3789 77.8571 58.3789C75.7926 58.3789 73.8127 59.1989 72.3526 60.6585L66.1786 66.8326L56.9252 57.5793L93.4286 21.0759V70.7254ZM15.5714 77.8571V66.1786L35.0357 46.726L40.3806 52.0747L45.8929 46.5625L40.5402 41.2098C39.0802 39.7502 37.1002 38.9302 35.0357 38.9302C32.9712 38.9302 30.9913 39.7502 29.5312 41.2098L15.5714 55.1696V15.5714H77.8571V7.78571H15.5714C13.5072 7.78778 11.528 8.60872 10.0684 10.0684C8.60872 11.528 7.78778 13.5072 7.78571 15.5714V77.8571H15.5714Z"
          fill="var(--light-text-color)"
        />
      </svg>
    );
  }

  return (
    <svg
      className={classes.svg}
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M109 5.5045L103.495 0L0 103.495L5.5045 109L13.2902 101.214H93.4286C95.4925 101.211 97.4711 100.39 98.9305 98.9305C100.39 97.4711 101.211 95.4925 101.214 93.4286V13.2902L109 5.5045ZM93.4286 93.4286H21.0759L51.413 63.0915L60.6741 72.3526C62.1341 73.8122 64.1141 74.6322 66.1786 74.6322C68.2431 74.6322 70.223 73.8122 71.6831 72.3526L77.8571 66.1786L93.4286 81.7383V93.4286ZM93.4286 70.7254L83.3616 60.6585C81.9016 59.1989 79.9216 58.3789 77.8571 58.3789C75.7926 58.3789 73.8127 59.1989 72.3526 60.6585L66.1786 66.8326L56.9252 57.5793L93.4286 21.0759V70.7254ZM15.5714 77.8571V66.1786L35.0357 46.726L40.3806 52.0747L45.8929 46.5625L40.5402 41.2098C39.0802 39.7502 37.1002 38.9302 35.0357 38.9302C32.9712 38.9302 30.9913 39.7502 29.5312 41.2098L15.5714 55.1696V15.5714H77.8571V7.78571H15.5714C13.5072 7.78778 11.528 8.60872 10.0684 10.0684C8.60872 11.528 7.78778 13.5072 7.78571 15.5714V77.8571H15.5714Z"
        fill="var(--dark-text-color)"
      />
    </svg>
  );
};
export default ImagePlaceholder;
