import { FormProps } from "./form-props";
import { Service } from "../../../api/models/service";
import { useEffect, useRef, useState } from "react";
import { DropdownRef } from "../../filter-components/filter-component";
import { FormValues } from "./form-data";
import Dropdown, {
  DropdownData,
} from "../../filter-components/dropdown/Dropdown";
import classes from "./Forms.module.scss";
import BasicInput from "../../inputs/basic-input/BasicInput";
import { Category } from "../../../api/models/category";
import ImageInput from "../../inputs/image-input/ImageInput";
import { checkServiceValidity } from "./AddEditService";
import { CategoriesAPI } from "../../../api/services/CategoriesAPI";
import { DaysAPI } from "../../../api/services/DaysAPI";
import { CoachesAPI } from "../../../api/services/CoachesAPI";
import { Coach } from "../../../api/models/coach";
import { Day } from "../../../api/models/day";

const AddEditProduct = (props: FormProps) => {
  let data = props.data as Service;
  const categoriesDropdown = useRef<DropdownRef>(null);
  const [categoriesArray, setCategoriesArray] = useState([] as Category[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<FormValues>({
    title: data?.title || "",
    price: data?.price || "",
    description: data?.description || "",
    feature_image_url: data?.feature_image_url || null,
    is_product: true,
    categories: data?.categories?.map((item) => item.category_id) || [],
  });

  const onChangeHandler = (propertyName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  const handleCategorySelection = (categoriesArray: DropdownData[]) => {
    setFormData((prevState) => ({
      ...prevState,
      categories: categoriesArray.map((item) => item.id),
    }));
  };

  useEffect(() => {
    props.onFormValidityChange(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsValid(checkServiceValidity(formData));
    props.onFormValueChange(formData);
  }, [formData]);

  // data fetching
  useEffect(() => {
    CategoriesAPI.getAll()
      .then((data) => {
        setCategoriesArray(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (categoriesDropdown.current && formData.categories) {
      categoriesDropdown.current.setValue(formData.categories);
    }
  }, [isLoading]);

  return (
    <form className={`${classes.form}`}>
      <div className={classes.group}>
        <div className={classes.row}>
          <BasicInput
            placeholder={"Názov"}
            required={true}
            onChange={(value) => {
              onChangeHandler("title", value);
            }}
            value={formData.title}
          />
          <BasicInput
            type={"text"}
            placeholder={"Cena"}
            required={true}
            onChange={(value) => {
              onChangeHandler("price", value);
            }}
            value={formData.price}
          />
        </div>
        <div className={classes.row}>
          <BasicInput
            placeholder={"Popis"}
            required={true}
            onChange={(value) => {
              onChangeHandler("description", value);
            }}
            value={formData.description}
          />
        </div>
      </div>
      <div className={classes.group}>
        <h2 className={classes.heading}>Kategórie</h2>
        <Dropdown
          placeholder={"Vyberte kategórie"}
          data={categoriesArray.map((category) => ({
            id: category.category_id,
            title: category.name,
          }))}
          ref={categoriesDropdown}
          onSelection={handleCategorySelection}
          isModalDropdown={true}
          selectMultiple={true}
        />
      </div>
      <div className={classes.group}>
        <ImageInput
          onChange={(image) => {
            onChangeHandler("featureImage", image);
          }}
          value={formData?.feature_image_url}
        />
      </div>
    </form>
  );
};
export default AddEditProduct;
