import classes from "./ServicesFilter.module.scss";
import Search from "../search/Search";
import { Category } from "../../../api/models/category";
import { Day } from "../../../api/models/day";
import { Coach } from "../../../api/models/coach";
import Dropdown, { DropdownData } from "../dropdown/Dropdown";
import { useEffect, useRef, useState } from "react";
import trashcan from "../../../assets/images/icons/trashcan.svg";
import { AnimatePresence, motion } from "framer-motion";
import { FilterComponentsRef } from "../filter-component";
import { URLParams } from "../url-params";
import useFilterQueryParams from "../../../hooks/use-filter-query-params";
interface FilterProps {
  filterData: {
    categories: Category[];
    days: Day[];
    coaches: Coach[];
  };
}
const ServicesFilter = (props: FilterProps) => {
  const filterComponentsRef = useRef<FilterComponentsRef>([]);
  const {
    filterSettings,
    setQuery,
    reset: resetParams,
  } = useFilterQueryParams();
  const [loading, setLoading] = useState(true);
  const { filterData } = props;
  const handleQueryChange = (search: string) => {
    setQuery(URLParams.SEARCH, search);
  };
  const handleCategoryChange = (categories: DropdownData[]) => {
    setQuery(URLParams.CATEGORIES, categories);
  };
  const handleDayChange = (days: DropdownData[]) => {
    setQuery(URLParams.DAYS, days);
  };
  const handleCoachChange = (coaches: any[]) => {
    setQuery(URLParams.COACHES, coaches);
  };
  const setFilterValues = () => {
    Object.values(filterSettings).forEach((val, index) => {
      filterComponentsRef.current[index]?.setValue(val);
    });
  };
  const handleFilterReset = () => {
    filterComponentsRef.current.forEach((ref) => {
      ref?.close();
      ref?.reset();
    });
    resetParams();
  };

  useEffect(() => {
    if (
      filterData.coaches.length > 0 &&
      filterData.categories.length > 0 &&
      filterData.days.length > 0
    ) {
      setLoading(false);
    }
  }, [props.filterData]);

  useEffect(() => {
    setFilterValues();
  }, [loading]);

  return (
    <>
      <Search
        onChange={handleQueryChange}
        ref={(ref) => {
          filterComponentsRef.current[0] = ref;
        }}
      />
      {filterData.categories.length > 0 && (
        <Dropdown
          placeholder="Kategória"
          data={filterData.categories.map((category) => ({
            id: category?.category_id,
            title: category?.name,
          }))}
          onSelection={handleCategoryChange}
          ref={(ref) => {
            filterComponentsRef.current[1] = ref;
          }}
          selectMultiple={true}
        />
      )}
      {filterData.days.length > 0 && (
        <Dropdown
          placeholder="Deň"
          data={filterData.days.map((day) => ({
            id: day.day_id,
            title: day.name!,
          }))}
          onSelection={handleDayChange}
          ref={(ref) => {
            filterComponentsRef.current[2] = ref;
          }}
          selectMultiple={true}
        />
      )}
      {filterData.coaches.length > 0 && (
        <Dropdown
          placeholder="Tréner"
          data={filterData.coaches.map((coach) => ({
            id: coach.coach_id!,
            title: coach.name!,
          }))}
          onSelection={handleCoachChange}
          ref={(ref) => {
            filterComponentsRef.current[3] = ref;
          }}
          selectMultiple={true}
        />
      )}
      <AnimatePresence>
        {Object.values(filterSettings).some((val) => val.length > 0) && (
          <motion.button
            className={`square-button ${classes.reset}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={handleFilterReset}
          >
            <img src={trashcan} alt="reset button icon" />
          </motion.button>
        )}
      </AnimatePresence>
    </>
  );
};
export default ServicesFilter;
