import { GalleryData } from "../../pages/homepage/homepage-data";
import classes from "./MobileGallery.module.scss";
import { ColorScheme } from "../footer/Footer";
import ImagePlaceholder from "../image-placeholder/ImagePlaceholder";
import { UIEventHandler, useEffect, useRef, useState } from "react";
import React from "react";

interface MobileGalleryProps {
  imagesArray: GalleryData[];
}
const MobileGallery = (props: MobileGalleryProps) => {
  const [actualIndex, setActualIndex] = useState(0);
  const itemEls = useRef<HTMLDivElement[] | any>([]);
  const scrollHolder = useRef<HTMLDivElement | null>(null);
  let observer: IntersectionObserver;

  const handleScroll = (event: any) => {
    const elements = itemEls.current.map(
      // 20 is the offset
      (item: HTMLDivElement) => item.getBoundingClientRect().right - 50
    );

    for (let i = 0; i < elements.length; i++) {
      if (elements[i] >= 0) {
        setActualIndex(i);
        break;
      }
    }
  };

  return (
    <div className={classes["gallery-holder"]}>
      <div
        className={classes["scroll-holder"]}
        onScroll={handleScroll}
        ref={scrollHolder}
      >
        {props.imagesArray.map((image, index) => {
          return (
            <div
              className={`${classes["image-holder"]} ${
                actualIndex === index ? classes.active : ""
              }`}
              key={index}
              ref={(el) => (itemEls.current[index] = el)}
            >
              <div className={classes["image-content"]}>
                {image.src && <img src={image.src} alt="gallery photo" />}
                {!image.src && <ImagePlaceholder type={ColorScheme.LIGHT} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MobileGallery;
