import classes from "./BasicInput.module.scss";
import React, { useEffect, useRef, useState } from "react";

interface BasicInputOptions {
  type?: "text" | "number";
  placeholder?: string;
  required?: boolean;
  className?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const BasicInput = (props: BasicInputOptions) => {
  const [value, setValue] = useState("");
  const [isValid, setIsIValid] = useState(!props.required);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onBlurHandler = () => {
    if (inputRef.current?.value.length === 0 && props.required) {
      setIsIValid(false);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const checkValidity = () => {
    if (props.required) {
      if (value.length === 0) {
        setIsIValid(false);
      } else {
        setIsIValid(true);
      }
    }
  };

  useEffect(() => {
    checkValidity();
  }, [value]);

  return (
    <input
      className={`${classes.input} ${
        isValid ? classes.valid : classes.invalid
      } ${props.className}`}
      type={props.type}
      placeholder={props.placeholder}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      ref={inputRef}
      value={props.value}
    />
  );
};
export default BasicInput;
