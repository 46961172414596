import classes from "./Services.module.scss";
import MainHeading from "../../components/main-heading/MainHeading";
import ServicesFilter from "../../components/filter-components/services-filter/ServicesFilter";
import React, {useEffect, useState} from "react";
import {Service} from "../../api/models/service";
import ProductCard from "../../components/product-card/ProductCard";
import {FloatButton, message} from "antd";
import {adminActions} from "../../store/admin-slice";
import {
  CarryOutOutlined,
  EditOutlined,
  FolderOutlined,
  MenuOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import {useAppSelector} from "../../store/hooks";
import AdminModals from "../../components/admin-modals/AdminModals";
import {AdminModalType} from "../../components/admin-modals/admin-modal-type";
import {useDispatch} from "react-redux";
import {ItemInterface, ReactSortable} from "react-sortablejs";
import useFilterQueryParams from "../../hooks/use-filter-query-params";
import {ServicesAPI} from "../../api/services/ServicesAPI";
import {CategoriesAPI} from "../../api/services/CategoriesAPI";
import {DaysAPI} from "../../api/services/DaysAPI";
import {CoachesAPI} from "../../api/services/CoachesAPI";
import {Category} from "../../api/models/category";
import {Day} from "../../api/models/day";
import {Coach} from "../../api/models/coach";
import ProductCardLoading from "../../components/product-card/ProductCardLoading";
import useDocumentTitle from "../../hooks/use-document-title";

const Services = () => {
  useDocumentTitle({title: 'Budo Klub Katai - Služby'});
  const {
    modalOptions: {modalOpened},
    isAdmin,
  } = useAppSelector((store) => store.admin);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [servicesArray, setServicesArray] = useState<Service[]>([]);
  const [filteredArray, setFilteredArray] = useState<Service[]>([]);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [filterData, setFilterData] = useState({
    categories: [] as Category[],
    days: [] as Day[],
    coaches: [] as Coach[],
  });
  const [newServicesOrderArray, setNewServicesOrderArray] = useState<Service[]>(
    []
  );
  const [moveProducts, setMoveProducts] = useState(false);
  const {filterSettings} = useFilterQueryParams();

  const getCorrectServicesForm = (count: number) => {
    switch (count) {
      case 1 :
        return "Služba"
      case 2 :
      case 3 :
      case 4 :
        return "Služby"
      default:
        return "Služieb"
    }
  }
  const applyFilter = () => {
    const isFilterEnabled =
      filterSettings.search.length > 0 ||
      filterSettings.categories.length > 0 ||
      filterSettings.days.length > 0 ||
      filterSettings.coaches.length > 0;
    setFilterEnabled(isFilterEnabled);

    if (!isFilterEnabled) {
      return;
    }

    const filteredArray = servicesArray.filter((item) => {
      let titleMatch = true;
      let categoryMatch = true;
      let daysMatch = true;
      let coachesMatch = true;

      if (filterSettings.search.length > 0) {
        titleMatch = item.title
          ?.toLowerCase()
          .includes(filterSettings.search.toLowerCase())!;
      }

      if (filterSettings.categories.length > 0) {
        categoryMatch = filterSettings.categories.some((selectedCategory) => {
          return (
            item.categories
              ?.map((category) => category.category_id)
              ?.indexOf(selectedCategory) !== -1
          );
        });
      }

      if (filterSettings.days.length > 0) {
        daysMatch = filterSettings.days.some((selectedDay) => {
          return (
            item.days?.map((day) => day.day_id)?.indexOf(selectedDay) !== -1
          );
        });
      }

      if (filterSettings.coaches.length > 0) {
        coachesMatch = filterSettings.coaches.some((selectedCoach) => {
          return (
            item.coaches
              ?.map((coach) => coach.coach_id)
              ?.indexOf(selectedCoach) !== -1
          );
        });
      }

      return titleMatch && categoryMatch && daysMatch && coachesMatch;
    });

    setFilteredArray(filteredArray);
  };
  const handleModalOpen = (type: AdminModalType) => {
    dispatch(adminActions.openModal({modalType: type}));
  };

  const cancelMovingHandler = () => {
    setMoveProducts(false);
    setNewServicesOrderArray(servicesArray);
  };

  const confirmRearrangementHandler = async () => {
    try {
      await ServicesAPI.rearrange(
        newServicesOrderArray.map((item) => item.service_id)
      );
      messageApi.open({
        type: "success",
        content: "Nové poradie služieb bolo uložené",
      });
      setServicesArray(newServicesOrderArray);
      setMoveProducts(false);
    } catch (err: any) {
      messageApi.open({
        type: "error",
        content: err.message,
      });
    }
  };
  const handleListRearrangement = (newArray: ItemInterface[]) => {
    const servicesMap = new Map(
      newServicesOrderArray.map((service) => [service.service_id, service])
    );

    const rearrangedServices: any[] = newArray
      .map((item: any) => servicesMap.get(item.id))
      .filter((service) => service);

    setNewServicesOrderArray(rearrangedServices);
  };

  const handleHTTPSuccess = (
    message: string,
    type: AdminModalType,
    data: any
  ) => {
    messageApi.open({
      type: "success",
      content: message,
    });
    switch (type) {
      case AdminModalType.ADD_SERVICE:
      case AdminModalType.ADD_PRODUCT: {
        setLoading(true);
        setServicesArray((prevState) => [...prevState, data]);
        setFilteredArray((prevState) => [...prevState, data]);
        break;
      }
      case AdminModalType.EDIT_SERVICE:
      case AdminModalType.EDIT_PRODUCT: {
        setLoading(true);
        setServicesArray((prevState) => {
          if (data && "service_id" in data) {
            const index = prevState.findIndex(
              (item) => item.service_id === data.service_id
            );

            if (~index) {
              prevState[index] = {...prevState[index], ...data};
            }
          }
          setFilteredArray([...prevState]);
          return [...prevState];
        });
        break;
      }
      case AdminModalType.ADD_CATEGORY: {
        setFilterData((prevState) => ({
          ...prevState,
          categories: [...prevState.categories, data],
        }));
        break;
      }
      case AdminModalType.EDIT_CATEGORY: {
        setFilterData((prevState) => {
          if (data && "category_id" in data) {
            const index = prevState.categories.findIndex(
              (item) => item.category_id === data.category_id
            );
            if (~index) {
              prevState.categories[index] = data;
            }
          }

          return {
            ...prevState,
            categories: [...prevState.categories],
          };
        });
        break;
      }

      case AdminModalType.DELETE_CATEGORY: {
        setFilterData((prevState) => {
          const index = prevState.categories.findIndex(
            (item) => item.category_id === data
          );
          if (~index) {
            prevState.categories.splice(index, 1);
          }
          return {...prevState};
        });

        break;
      }
      case AdminModalType.DELETE_SERVICE: {
        setLoading(true);
        setServicesArray((prevState) => {
          const index = prevState.findIndex((item) => item.service_id === data);
          prevState.splice(index, 1);
          setFilteredArray([...prevState]);
          return [...prevState];
        });

        break;
      }
    }
  };
  const handleHTTPError = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const fetchServices = () => {
    setLoading(true);
    ServicesAPI.getAll()
      .then((data) => {
        setServicesArray(data);
        setNewServicesOrderArray(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFilterData = () => {
    setLoadingFilter(true);
    const fetchArray = [
      CategoriesAPI.getAll(),
      DaysAPI.getAll(),
      CoachesAPI.getAll(),
    ];

    Promise.all(fetchArray)
      .then(([categories, days, coaches]) => {
        setFilterData({
          categories: categories as Category[],
          days: days as Day[],
          coaches: coaches as Coach[],
        });
      })
      .finally(() => {
        setLoadingFilter(false);
      });
  };

  // data fetching
  useEffect(() => {
    fetchServices();
    fetchFilterData();
  }, []);

  useEffect(() => {
    setNewServicesOrderArray(servicesArray);
    setLoading(false);
  }, [servicesArray]);

  useEffect(() => {
    if (!loading && !loadingFilter) {
      applyFilter();
    }
  }, [loading, loadingFilter, filterSettings]);

  const adminOptions = (
    <FloatButton.Group
      shape={"circle"}
      icon={<MenuOutlined/>}
      type={"default"}
      trigger={"hover"}
      badge={{dot: true}}
    >
      <FloatButton
        icon={<FolderOutlined/>}
        tooltip={<p>Pridať kategóriu</p>}
        onClick={() => {
          handleModalOpen(AdminModalType.ADD_CATEGORY);
        }}
      />
      <FloatButton
        icon={<EditOutlined/>}
        tooltip={<p>Upraviť kategórie</p>}
        onClick={() => {
          handleModalOpen(AdminModalType.EDIT_DELETE_CATEGORY);
        }}
      />
      <FloatButton
        icon={<CarryOutOutlined/>}
        tooltip={<p>Pridať službu</p>}
        onClick={() => {
          handleModalOpen(AdminModalType.ADD_SERVICE);
        }}
      />
      <FloatButton
        icon={<TagsOutlined/>}
        tooltip={<p>Pridať produkt</p>}
        onClick={() => {
          handleModalOpen(AdminModalType.ADD_PRODUCT);
        }}
      />
      <FloatButton
        icon={<EditOutlined/>}
        tooltip={<p>Upraviť služby/produkty</p>}
        onClick={() => {
          setMoveProducts(true);
        }}
      />
    </FloatButton.Group>
  );

  return (
    <>
      {contextHolder}
      <section className={`${classes.introduction} introduction`}>
        <div className="classic-content">
          <MainHeading headingText={`${loading ? '-' : servicesArray.length} ${getCorrectServicesForm(servicesArray.length)}!`}/>
          <p className={"main-desc"}>
            Získajte svoju vysnívanú postavu s našimi fitness službami, <br/>
            ktoré vás posunú na ďalšiu úroveň.
          </p>
        </div>
      </section>
      <section className={classes.services}>
        <div className="classic-content">
          <div className={classes["filter-row"]}>
            {!moveProducts && <ServicesFilter filterData={filterData}/>}
            {moveProducts && (
              <>
                <button
                  onClick={cancelMovingHandler}
                  className={`${classes["cancel-button"]} classic-button`}
                >
                  Zrušiť
                </button>
                <button
                  onClick={confirmRearrangementHandler}
                  className={`${classes["confirm-button"]} classic-button`}
                >
                  Uložiť
                </button>
              </>
            )}
          </div>
          {moveProducts && (
            <ReactSortable
              className={classes["services-grid"]}
              list={
                newServicesOrderArray.map((item) => ({
                  id: item.service_id,
                  name: item.title,
                })) as ItemInterface[]
              }
              setList={handleListRearrangement}
            >
              {newServicesOrderArray.map((service) => {
                return <ProductCard data={service} key={service.service_id}/>;
              })}
            </ReactSortable>
          )}
          {!moveProducts && (
            <div className={classes["services-grid"]}>
              {loading && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => {
                return <ProductCardLoading key={index}/>
              })}
              {!loading &&
                  <>
                    {filterEnabled && (
                      <>
                        {filteredArray.map((service) => {
                          return (
                            <ProductCard data={service} key={service.service_id}/>
                          );
                        })}
                      </>
                    )}
                    {!filterEnabled && (
                      <>
                        {servicesArray.map((service) => {
                          return (
                            <ProductCard data={service} key={service.service_id}/>
                          );
                        })}
                      </>
                    )}
                  </>
              }
            </div>
          )}
        </div>
      </section>
      {isAdmin && modalOpened && (
        <AdminModals onSuccess={handleHTTPSuccess} onError={handleHTTPError}/>
      )}
      {isAdmin && adminOptions}
    </>
  );
};

export default Services;
