import classes from "./Forms.module.scss";
import BasicInput from "../../inputs/basic-input/BasicInput";
import { useEffect, useState } from "react";
import { FormProps } from "./form-props";

const AddCategory = (props: FormProps) => {
  const [isValid, setIsValid] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const onChangeHandler = (value: string) => {
    setCategoryName(value);
    setIsValid(value.length > 0);
    props.onFormValueChange({
      name: value,
    });
  };

  useEffect(() => {
    props.onFormValidityChange(isValid);
  }, [isValid]);

  return (
    <form className={`${classes.form}`}>
      <BasicInput
        required={true}
        placeholder={"Názov kategórie"}
        className={classes["category-input"]}
        onChange={onChangeHandler}
        value={categoryName}
      />
    </form>
  );
};
export default AddCategory;
