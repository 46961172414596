import classes from "./Dropdown.module.scss";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { DropdownRef } from "../filter-component";

export interface DropdownData {
  id: any;
  title: string;
}

interface DropdownProps {
  data: DropdownData[];
  placeholder: string;
  isModalDropdown?: boolean;
  onSelection?: (selectedItems: any[]) => void;
  onItemSelection?: (selectedItem: DropdownData | undefined) => void;
  selectMultiple?: boolean;
}

const Dropdown = forwardRef<DropdownRef, DropdownProps>(
  (props: DropdownProps, ref) => {
    const [isOpened, setIsOpened] = useState(false);
    const [checkedList, setCheckedList] = useState<DropdownData[]>([]);
    const [selectedItem, setSelectedItem] = useState<DropdownData | undefined>(
      undefined
    );
    const componentRef = useClickAway<HTMLDivElement>(() => {
      setIsOpened(false);
    });

    useImperativeHandle(ref, () => ({
      close() {
        setIsOpened(false);
      },
      reset() {
        setCheckedList([]);
        setSelectedItem(undefined);
      },
      setValue(selectedItems: any[]) {
        const items = props.data.filter(
          (item) => selectedItems.indexOf(item.id) !== -1
        );
        setCheckedList(items);
      },
    }));

    const selectItem = (item: DropdownData) => {
      if (props.onItemSelection) {
        item.id === selectedItem?.id
          ? props.onItemSelection(undefined)
          : props.onItemSelection(item);
      }
      setSelectedItem((selectedItem) => {
        if (item.id === selectedItem?.id) {
          return undefined;
        }
        return item;
      });
    };

    const toggleItem = (item: DropdownData) => {
      let newList = [...checkedList];
      if (newList.some((listItem) => listItem.id === item.id)) {
        newList = newList.filter((listItem) => listItem.id !== item.id);
      } else {
        newList.push(item);
      }

      setCheckedList(newList);
      if (props.onSelection) {
        props.onSelection(newList); // Pass newList as an array of DropdownData
      }
    };

    const toggleOpen = () => {
      setIsOpened((opened) => !opened);
    };

    const itemsList = (
      <div className={classes["items-list"]}>
        <div
          className={`${classes["scroll-holder"]} ${
            props.isModalDropdown ? "dark-scrollbar" : "light-scrollbar"
          }`}
        >
          {props.data.map((item) => {
            return (
              <div
                className={classes.item}
                key={item.id}
                onClick={() => {
                  props.selectMultiple ? toggleItem(item) : selectItem(item);
                }}
              >
                <div
                  className={`${classes.checkbox} ${
                    !props.selectMultiple
                      ? item.id === selectedItem?.id
                        ? classes.checked
                        : ""
                      : checkedList.some((listItem) => listItem.id === item.id)
                      ? classes.checked
                      : ""
                  }`}
                ></div>
                <p>{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
    return (
      <div
        ref={componentRef}
        className={`classic-button ${classes.wrapper} ${
          isOpened ? classes.opened : ""
        } ${
          checkedList.length > 0 || selectedItem ? classes["filter-active"] : ""
        } ${props.isModalDropdown ? classes.modal : ""} ${
          props.data.length === 0 ? classes.disabled : ""
        }`}
      >
        <div className={classes.placeholder} onClick={toggleOpen}>
          <p className={`filter-text`}>
            {props.selectMultiple && (
              <>
                {checkedList.length > 0
                  ? checkedList.map((item) => item.title).join(", ")
                  : props.placeholder}
              </>
            )}
            {!props.selectMultiple && (
              <>{selectedItem ? selectedItem.title : props.placeholder}</>
            )}
          </p>
          <svg
            className={classes.arrow}
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.58925 6.256C5.26383 6.58141 4.73617 6.58141 4.41075 6.256L0.244075 2.08934C-0.0813583 1.7639 -0.0813583 1.23626 0.244075 0.910823C0.569517 0.58539 1.09715 0.58539 1.42259 0.910823L5 4.48825L8.57742 0.910823C8.90283 0.58539 9.4305 0.58539 9.75592 0.910823C10.0813 1.23626 10.0813 1.7639 9.75592 2.08934L5.58925 6.256Z"
              fill={
                props.isModalDropdown
                  ? "var(--dark-text-color)"
                  : "var(--light-text-color)"
              }
            />
          </svg>
        </div>
        {itemsList}
      </div>
    );
  }
);
export default Dropdown;
