import classes from "./Coaches.module.scss";
import MainHeading from "../../components/main-heading/MainHeading";
import {Coach} from "../../api/models/coach";
import CoachCard from "../../components/coach-card/CoachCard";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {FloatButton, message} from "antd";
import {MenuOutlined, UserAddOutlined} from "@ant-design/icons";
import AdminModals from "../../components/admin-modals/AdminModals";
import {adminActions} from "../../store/admin-slice";
import {AdminModalType} from "../../components/admin-modals/admin-modal-type";
import {useEffect, useState} from "react";
import {CoachesAPI} from "../../api/services/CoachesAPI";
import CoachCardLoading from "../../components/coach-card/CoachCardLoading";
import useDocumentTitle from "../../hooks/use-document-title";

const Coaches = () => {
  useDocumentTitle({title: 'Budo Klub Katai - Tréneri'});
  const {
    isAdmin,
    modalOptions: {modalOpened},
  } = useAppSelector((store) => store.admin);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [coachesArray, setCoachesArray] = useState<Coach[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const handleModalOpen = (type: AdminModalType) => {
    dispatch(adminActions.openModal({modalType: type}));
  };

  const handleHTTPSuccess = (
    message: string,
    type: AdminModalType,
    data: any
  ) => {
    messageApi.open({
      type: "success",
      content: message,
    });
    switch (type) {
      case AdminModalType.ADD_COACH: {
        setCoachesArray((prevState) => [...prevState, data]);
        break;
      }
      case AdminModalType.EDIT_COACH: {
        setCoachesArray((prevState) => {
          if (data && "coach_id" in data) {
            const index = prevState.findIndex(
              (item) => item.coach_id === data.coach_id
            );
            if (~index) {
              prevState[index] = data;
            }
          }
          return [...prevState];
        });
        break;
      }
      case AdminModalType.DELETE_COACH: {
        setCoachesArray((prevState) => {
          const index = prevState.findIndex((item) => item.coach_id === data);
          if (~index) {
            prevState.splice(index, 1);
          }
          return [...prevState];
        });
        break;
      }
    }
  };
  const handleHTTPError = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const fetchCoaches = () => {
    setLoading(true);
    CoachesAPI.getAll().then((data) => {
      setCoachesArray(data);
    }).finally(() => {
      setLoading(false);
    });
  };

  // data fetching
  useEffect(() => {
    fetchCoaches();
  }, []);

  const adminOptions = (
    <FloatButton.Group
      shape={"circle"}
      icon={<MenuOutlined/>}
      type={"default"}
      trigger={"hover"}
      badge={{dot: true}}
    >
      <FloatButton
        icon={<UserAddOutlined/>}
        tooltip={<p>Pridať trenéra</p>}
        onClick={() => {
          handleModalOpen(AdminModalType.ADD_COACH);
        }}
      />
    </FloatButton.Group>
  );

  return (
    <>
      {contextHolder}
      {isAdmin && adminOptions}
      <section className={`${classes.introduction} introduction`}>
        <div className="classic-content">
          <MainHeading headingText={"Máme srdce pre šport"}/>
          <p className={"main-desc"}>
            Naši tréneri sú tu aj pre Vás. S úsmevom a odhodlaním <br/> pomôžu
            dosiahnuť cieľ po ktorom túžite. Riadime sa <br/> mottom: “funkčné
            telo v každom veku.”!
          </p>
        </div>
      </section>
      <section className={classes.coaches}>
        <div className={`classic-content`}>
          <div className={`${classes["cards-grid"]}`}>
            {
              loading &&
                <>
                {[0,1,2,3].map((index)=>{
                  return <CoachCardLoading key={index}/>
                })}
                </>
            }
            {!loading &&
                <>
                  {coachesArray.map((coach) => {
                    return <CoachCard key={coach.coach_id} data={coach}/>;
                  })}
                </>
            }
          </div>
        </div>
      </section>
      {isAdmin && modalOpened && (
        <AdminModals onSuccess={handleHTTPSuccess} onError={handleHTTPError}/>
      )}
    </>
  );
};

export default Coaches;
