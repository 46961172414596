import axios from "axios";
import {config} from "./httpConfig";
import {LocalStorageKeys} from "../enums/localStorage-keys";

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
    LocalStorageKeys.ID_TOKEN
);

export const refreshToken = () => {
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem(
        LocalStorageKeys.ID_TOKEN
    );
}
export default axios;
