import classes from "./ImageInput.module.scss";
import React, { useEffect, useRef, useState } from "react";

interface ImageInputProps {
  onChange: (file?: File) => void;
  value?: string;
}
const ImageInput = (props: ImageInputProps) => {
  const [fileName, setFileName] = useState<string | undefined>(props.value);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onFileUpload = () => {
    const file = inputRef.current?.files?.item(0);
    setFileName(file?.name);
    if (file) {
      props.onChange(file);
    }
  };

  const openInputHandler = (event: any) => {
    event.preventDefault();
    inputRef.current?.click();
  };

  const deleteFileHandler = (event: any) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setFileName(undefined);
    props.onChange(undefined);
  };

  return (
    <>
      <div
        className={`${classes["button-holder"]} ${
          fileName ? classes.active : ""
        }`}
        onClick={(event) => {
          fileName ? deleteFileHandler(event) : openInputHandler(event);
        }}
      >
        <button className={classes.button}>{fileName ? "-" : "+"}</button>
        <p>{fileName || "Pridať fotografiu"}</p>
      </div>
      <input
        className={classes["file-input"]}
        type="file"
        accept={".jpg,.jpeg"}
        ref={inputRef}
        onChange={onFileUpload}
      />
    </>
  );
};
export default ImageInput;
