import { Service } from "../models/service";
import axios from "../configs/api-config";
export const ServicesAPI = {
  getAll: async () => {
    const { data } = await axios.request({
      method: "GET",
      url: "/services",
    });
    return data as Service[];
  },
  rearrange: async (orderArray: any[]) => {
    try {
      await axios.request({
        method: "POST",
        url: "/services/custom-order",
        data: orderArray,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
  create: async (formData: any) => {
    try {
      const { data } = await axios.request({
        url: "/services",
        method: "POST",
        data: formData,
      });
      return data as Service;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  edit: async (formData: any, id: string) => {
    try {
      const { data } = await axios.request({
        method: "PUT",
        url: `/services/${id}`,
        data: formData,
      });
      return data as Service;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  delete: async (id: string) => {
    try {
      await axios.request({
        method: "DELETE",
        url: `/services/${id}`,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
};
