import classes from "./MainNavigation.module.scss";
import kataiLogoWhite from "../../assets/images/logos/katai-white.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useState } from "react";
import { motion } from "framer-motion";
import {
  mobileLinksVariants,
  mobileMenuImageVariants,
  mobileMenuVariants,
} from "./navigation-animations";
import { AuthAPI } from "../../api/services/AuthAPI";

export interface NavigationProps {
  isFixed: boolean;
  isAdmin: boolean;
}

const routes = [
  {
    id: "uvod",
    path: "/",
    routeName: "Úvod",
  },
  {
    id: "sluzby",
    path: "/sluzby",
    routeName: "Služby",
  },
  {
    id: "treneri",
    path: "/treneri",
    routeName: "Tréneri",
  },
];
const MainNavigation = (props: NavigationProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isMobile = useMediaQuery("only screen and (max-width: 640px)");
  const navigate = useNavigate();

  const toggleMenuHandler = () => {
    setIsMenuOpened((isOpened) => !isOpened);
  };

  const navigaitonHandler = (route: string) => {
    route === window.location.pathname
      ? window.scrollTo({ top: 0, behavior: "smooth" })
      : navigate(route);
  };

  const logoutHandler = () => {
    AuthAPI.logOut();
    navigate("/katai-login");
  };

  const menuLinks = (
    <ul className={classes["menu-items"]}>
      {routes.map((route) => {
        return (
          <NavLink
            key={route.id}
            to={route.path}
            className={({ isActive }) =>
              `${isActive ? classes.active : ""} ${
                route.id === "sluzby" ? classes.marked : ""
              }`
            }
            onClick={() => {
              setIsMenuOpened(false);
            }}
          >
            {route.routeName}
          </NavLink>
        );
      })}
      {props.isAdmin && (
        <button className={classes.logout} onClick={logoutHandler}>
          Odhlásiť sa
        </button>
      )}
    </ul>
  );

  const menuButton = (
    <>
      <button className={classes["menu-button"]} onClick={toggleMenuHandler}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  );

  const mobileMenuContent = (
    <motion.div
      className={`${classes["mobile-menu-holder"]} ${
        isMenuOpened ? classes.opened : ""
      }`}
      animate={isMenuOpened ? "menuOpening" : "menuClosing"}
      variants={mobileMenuVariants}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <motion.img
        src={kataiLogoWhite}
        className={classes["menu-logo"]}
        variants={mobileMenuImageVariants}
        animate={isMenuOpened ? "menuOpening" : "menuClosing"}
        transition={{ duration: 0.6 }}
        alt="bkk logo"
      />
      <motion.div
        className={`${classes["menu-content"]} classic-content`}
        variants={mobileLinksVariants}
        animate={isMenuOpened ? "menuOpening" : "menuClosing"}
        transition={{ duration: 0.3 }}
      >
        {menuLinks}
      </motion.div>
    </motion.div>
  );

  return (
    <>
      <nav
        className={`${props.isFixed ? classes.fixed : ""} ${
          isMenuOpened ? classes.opened : ""
        }`}
      >
        <div className={`${classes["navigation-content"]} classic-content`}>
          <a
            onClick={() => {
              navigaitonHandler("/");
            }}
            className={classes["menu-logo"]}
          >
            <img src={kataiLogoWhite} alt="BKK logo white" />
          </a>
          {!isMobile ? menuLinks : menuButton}
        </div>
      </nav>
      {isMobile && mobileMenuContent}
    </>
  );
};
export default MainNavigation;
