export const mobileMenuVariants = {
  menuClosing: {
    height: 0,
    transition: {
      height: {
        delay: 0.6,
      },
    },
  },
  menuOpening: {
    height: "calc(100vh - 55px)",
  },
};

export const mobileMenuImageVariants = {
  menuOpening: {
    opacity: [0, 0.05],
    transition: {
      opacity: {
        delay: 0.6,
      },
    },
  },
  menuClosing: {
    opacity: [0.05, 0],
    transition: {
      opacity: {
        delay: 0.2,
      },
    },
  },
};

export const mobileLinksVariants = {
  menuOpening: {
    opacity: 1,
    transition: {
      opacity: {
        delay: 0.7,
      },
    },
  },
  menuClosing: {
    opacity: 0,
  },
};
