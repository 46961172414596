import classes from "./SlidesIndicator.module.scss";
import { forwardRef, useEffect, useState } from "react";

export interface SlidesIndicatorOptions {
  actualIndex: number;
  slidesCount: number;
  slideDuration: number;
}
interface SlidesIndicatorProps {
  options: SlidesIndicatorOptions;
  changeIndex: (index: number) => void;
}
const SlidesIndicator = forwardRef((props: SlidesIndicatorProps, ref) => {
  const [animate, setAnimate] = useState(false);
  const changeIndexHandler = (index: number) => {
    props.changeIndex(index);
  };

  useEffect(() => {
    setAnimate(false);
    document.documentElement.style.setProperty(
      "--slide-duration",
      `${props.options.slideDuration}ms`
    );
    setTimeout(() => {
      setAnimate(true);
    }, 50);
  }, [props.options.slideDuration]);

  return (
    <div className={classes["wrapper"]}>
      {Array.from(Array(props.options.slidesCount)).map((item, index) => {
        return (
          <div
            key={index}
            className={`${classes.dot} ${
              index === props.options.actualIndex ? classes.active : ""
            }`}
            onClick={() => {
              changeIndexHandler(index);
            }}
          >
            <div
              className={`${classes["inner-bar"]} ${
                props.options.actualIndex === index && animate
                  ? classes.active
                  : ""
              }`}
            ></div>
          </div>
        );
      })}
    </div>
  );
});

export default SlidesIndicator;
