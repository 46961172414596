import classes from "../../admin-modals/forms/Forms.module.scss";
import adminModalClasses from "../../admin-modals/AdminModals.module.scss";

import Dropdown, {
  DropdownData,
} from "../../filter-components/dropdown/Dropdown";
import BasicInput from "../basic-input/BasicInput";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Day } from "../../../api/models/day";
import {
  DayInputRef,
  DropdownRef,
} from "../../filter-components/filter-component";
import { DayTime } from "../../../api/models/day-time";

const timeExp = /^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/;

interface DaysInputProps {
  days: Day[];
  onDayAdd: (day: DayTime) => void;
}
const DaysInput = forwardRef<DayInputRef, DaysInputProps>((props, ref) => {
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [selectedDay, setSelectedDay] = useState<DropdownData | undefined>(
    undefined
  );
  const dropdownRef = useRef<DropdownRef>(null);

  useImperativeHandle(ref, () => ({
    reset() {
      setTimeFrom("");
      setTimeTo("");
      setSelectedDay(undefined);
      if (dropdownRef.current) {
        dropdownRef.current.reset();
      }
    },
  }));

  const selectDayHandler = (day: DropdownData | undefined) => {
    setSelectedDay(day);
  };

  const timeFromChangeHandler = (value: string) => {
    setTimeFrom(value);
  };
  const timeToChangeHandler = (value: string) => {
    setTimeTo(value);
  };

  const addDayHandler = (event: any) => {
    event.preventDefault();
    if (selectedDay) {
      props.onDayAdd({
        day_id: selectedDay.id,
        time_from: timeFrom,
        time_to: timeTo,
      });
    }
  };

  return (
    <div className={classes["days-input"]}>
      <div className={classes["dropdown-wrapper"]}>
        <Dropdown
          data={props.days.map((day) => ({ id: day.day_id, title: day.name! }))}
          ref={dropdownRef}
          placeholder={"Vyberte deň"}
          isModalDropdown={true}
          selectMultiple={false}
          onItemSelection={selectDayHandler}
        />
      </div>
      <BasicInput
        required={true}
        onChange={timeFromChangeHandler}
        placeholder={"Od"}
        value={timeFrom}
      />
      <BasicInput
        required={true}
        onChange={timeToChangeHandler}
        placeholder={"Do"}
        value={timeTo}
      />
      <button
        className={`${adminModalClasses["confirm-button"]} ${
          !(selectedDay && timeExp.test(timeFrom) && timeExp.test(timeTo))
            ? classes.disabled
            : ""
        }`}
        onClick={addDayHandler}
      >
        <p>Pridať</p>
      </button>
    </div>
  );
});
export default DaysInput;
