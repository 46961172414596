import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../components/main-navigation/MainNavigation";
import { useEffect, useState } from "react";
import classes from "./RootLayout.module.scss";
import Footer, { ColorScheme } from "../components/footer/Footer";
import { darkFooterPaths } from "../App";
import { message } from "antd";
import { AuthAPI } from "../api/services/AuthAPI";
import { LocalStorageKeys } from "../api/enums/localStorage-keys";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { adminActions } from "../store/admin-slice";

const RootLayout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAdmin } = useAppSelector((store) => store.admin);
  const [isHomepage, setIsHomepage] = useState(location.pathname === "/");
  const [footerStyle, setFooterStyle] = useState<ColorScheme>(ColorScheme.DARK);

  const checkFooterStyle = () => {
    darkFooterPaths.includes(location.pathname)
      ? setFooterStyle(ColorScheme.DARK)
      : setFooterStyle(ColorScheme.LIGHT);
  };

  const checkAdmin = () => {
    return !!localStorage.getItem(LocalStorageKeys.ID_TOKEN);
  };

  // navigation should be always fixed on homepage
  useEffect(() => {
    setIsHomepage(location.pathname === "/");
    checkFooterStyle();
    dispatch(adminActions.setIsAdmin({ isAdmin: checkAdmin() }));
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <MainNavigation isFixed={isHomepage} isAdmin={isAdmin} />
      <main className={classes["content-wrapper"]}>
        <Outlet></Outlet>
        <Footer type={footerStyle} />
      </main>
    </>
  );
};
export default RootLayout;
