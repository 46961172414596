import axios from "../configs/api-config";
import { Category } from "../models/category";
import footer from "../../components/footer/Footer";
export const CategoriesAPI = {
  getAll: async () => {
    const { data } = await axios.request({
      method: "GET",
      url: "/categories",
    });
    return data as Category[];
  },
  edit: async (formData: any, id: string) => {
    try {
      const { data } = await axios.request({
        method: "PUT",
        url: `/categories/${id}`,
        data: formData,
      });
      return data as Category;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  create: async (formData: any) => {
    try {
      const { data } = await axios.request({
        method: "POST",
        url: "/categories",
        data: formData,
      });
      return data as Category;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  delete: async (id: string) => {
    try {
      await axios.request({
        method: "DELETE",
        url: `/categories/${id}`,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
};
