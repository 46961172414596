import classes from "../../admin-modals/forms/Forms.module.scss";
import adminModalClasses from "../../admin-modals/AdminModals.module.scss";
import Dropdown, {
  DropdownData,
} from "../../filter-components/dropdown/Dropdown";
import BasicInput from "../basic-input/BasicInput";
import { useRef, useState } from "react";
import { DropdownRef } from "../../filter-components/filter-component";
import { PlatformInput } from "../../../api/models/platform-input";

interface PlatformsInputProps {
  platformsArray: DropdownData[];
  onPlatformAdd: (data: PlatformInput) => void;
}
const PlatformsInput = (props: PlatformsInputProps) => {
  const [linkValue, setLinkValue] = useState("");
  const platformDropdownRef = useRef<DropdownRef>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<
    DropdownData | undefined
  >(undefined);

  const handlePlatformSelection = (data?: DropdownData) => {
    setSelectedPlatform(data);
  };

  const addPlatformHandler = (event: any) => {
    event.preventDefault();

    if (!selectedPlatform) {
      return;
    }

    props.onPlatformAdd({
      platform_key: selectedPlatform.id,
      platform_value: linkValue,
    });

    setLinkValue("");
    setSelectedPlatform(undefined);
    if (platformDropdownRef.current) {
      platformDropdownRef.current.reset();
    }
  };

  const platformLinkChangeHandler = (value: string) => {
    setLinkValue(value);
  };

  return (
    <div className={classes["platform-input"]}>
      <Dropdown
        data={props.platformsArray}
        placeholder={"Vyberte platformu"}
        isModalDropdown={true}
        onItemSelection={handlePlatformSelection}
        ref={platformDropdownRef}
      />
      <BasicInput
        required={true}
        placeholder={`https://www.${selectedPlatform?.title || ""}...`}
        onChange={platformLinkChangeHandler}
        value={linkValue}
      />
      <button
        onClick={addPlatformHandler}
        className={`${adminModalClasses["confirm-button"]} ${
          !(selectedPlatform && linkValue.length > 0) ? classes.disabled : ""
        }`}
      >
        <p>Pridať</p>
      </button>
    </div>
  );
};
export default PlatformsInput;
