import axios from "../configs/api-config";
export const ServicesImagesAPI = {
  uploadFeature: async (id: string, image: File) => {
    let bodyFormData = new FormData();
    bodyFormData.set("feature_image", image);

    try {
      const { data } = await axios.request({
        method: "POST",
        url: `/services/${id}/feature-image`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      });
      return data as string;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  deleteFeature: async (id: string) => {
    try {
      await axios.request({
        method: "DELETE",
        url: `/services/${id}/feature-image`,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
};
