import { useEffect, useRef, useState } from "react";
import classes from "./IntersectionAnimate.module.scss";
export enum Animations {
  FADE_IN_UP,
  FADE_IN_DOWN,
}
interface AnimateOptions {
  animationType: Animations;
  animationTime?: number;
  animationDelay?: number;
  playOnce?: boolean;
  intersectionRatio?: number;
  offset?: string;
}

const defaultOptions: AnimateOptions = {
  animationType: Animations.FADE_IN_UP,
  animationTime: 500,
  animationDelay: 0,
  playOnce: true,
  intersectionRatio: 1,
  offset: "0px 0px 0px 0px",
};

const IntersectionAnimate = (props: {
  children: any;
  className?: string;
  styles?: {
    [property: string]: string;
  };
  options: AnimateOptions;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  let observer: IntersectionObserver;

  const options = { ...defaultOptions, ...props.options };

  const setIntersection = (entry: IntersectionObserverEntry) => {
    const showElement =
      entry.isIntersecting &&
      entry.intersectionRatio >= options.intersectionRatio!;
    setIsVisible(showElement);
    if (showElement && options.playOnce) {
      observer.disconnect();
    }
  };

  const getClassName = (animationType: Animations) => {
    if (!isVisible) {
      return "";
    }

    switch (animationType) {
      case Animations.FADE_IN_UP:
        return classes["fade-in-up"];
      case Animations.FADE_IN_DOWN:
        return classes["fade-in-down"];
    }
  };

  useEffect(() => {
    observer = new IntersectionObserver(
      (entries) => {
        setIntersection(entries[0]);
      },
      {
        threshold: 1,
        rootMargin: options.offset,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current as Element);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`${classes.div} ${getClassName(options.animationType)} ${
        props.className
      }`}
      style={{
        animationDelay: `${options.animationDelay}ms`,
        animationDuration: `${options.animationTime || 500}ms`,
        ...props.styles,
      }}
    >
      {props.children}
    </div>
  );
};
export default IntersectionAnimate;
