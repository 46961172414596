import classes from "./ImagesCarousel.module.scss";
import MainHeading from "../main-heading/MainHeading";
import SlidesIndicator from "../slides-indicator/SlidesIndicator";
import { useEffect, useRef } from "react";
import { slideshowActions } from "../../store/slideshow-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import IntersectionAnimate, {
  Animations,
} from "../intersection-animate/IntersectionAnimate";
import { clear } from "@testing-library/user-event/dist/clear";

export interface CarouselData {
  id: number;
  image?: any;
  video?: any;
  title: string;
  description: string;
}

interface CarouselProps {
  itemsArray: CarouselData[];
  slideDuration: number;
}

type SlidesIndicatorRef = {
  startAnimation: (slideDuration: number) => void;
};

const ImagesCarousel = (props: CarouselProps) => {
  const dispatch = useAppDispatch();
  const slidesRef = useRef<SlidesIndicatorRef>(null);
  const actualIndex = useAppSelector(
    (store) => store.slideshow.actualSlideshowIndex
  );
  const actualSlideDuration = useAppSelector(
    (state) => state.slideshow.actualSlideDuration
  );

  const imagesCount = props.itemsArray.length;
  let slideInterval: any;

  const checkSlideDuration = async (): Promise<number> => {
    return new Promise((resolve, reject) => {
      if (props.itemsArray[actualIndex].video) {
        const video = document.createElement("video");

        video.addEventListener("loadedmetadata", () => {
          resolve(video.duration * 1000);
        });

        video.src = props.itemsArray[actualIndex].video.src;
      } else if (props.itemsArray[actualIndex].image) {
        const img = document.createElement("img");

        img.onload = () => {
          resolve(props.slideDuration);
        };

        img.src = props.itemsArray[actualIndex].image;
      }
    });
  };
  const startSlideshowInterval = async (duration: number) => {
    clearInterval(slideInterval);
    slideInterval = setInterval(() => {
      let newIndex = actualIndex + 1;
      if (newIndex > imagesCount - 1) {
        newIndex = 0;
      }
      dispatch(slideshowActions.setIndex({ newIndex }));
    }, duration);
  };

  const changeIndexHandler = async (index: number) => {
    dispatch(slideshowActions.setIndex({ newIndex: index }));
  };

  useEffect(() => {
    const startSlideshow = async () => {
      const duration = await checkSlideDuration();
      startSlideshowInterval(duration);
      dispatch(
        slideshowActions.setSlideshowDuration({ slideDuration: duration })
      );
    };

    startSlideshow();
    return () => {
      clearInterval(slideInterval);
    };
  }, [actualIndex]);

  return (
    <div className={classes["carousel-wrapper"]}>
      <div
        className={classes["slides-wrapper"]}
        style={{ transform: `translateX(-${actualIndex * 100}%)` }}
      >
        {props.itemsArray.map((item, index) => {
          return (
            <div className={classes["slide-holder"]} key={item.id}>
              {props.itemsArray[index].video &&
                !props.itemsArray[index].image && (
                  // @ts-ignore
                  <video playsInline={true} autoPlay={true} muted={true} loop={true}>
                    <source
                      src={props.itemsArray[index].video.src}
                      type="video/mp4"
                    />
                  </video>
                )}
              {props.itemsArray[index].image &&
                !props.itemsArray[index].video && (
                  <img src={item.image} alt="budo image" />
                )}
              <div className={classes["slide-content-holder"]}>
                <div className={`classic-content`}>
                  <div className={classes["text-holder"]}>
                    <IntersectionAnimate
                      options={{
                        animationType: Animations.FADE_IN_UP,
                        animationDelay: 300,
                      }}
                    >
                      <MainHeading headingText={item.title} />
                    </IntersectionAnimate>
                    <IntersectionAnimate
                      options={{
                        animationType: Animations.FADE_IN_UP,
                        animationDelay: 450,
                      }}
                    >
                      <p className={`main-desc ${classes["main-desc"]}`}>
                        {item.description}
                      </p>
                    </IntersectionAnimate>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes["slides-indicator"]}>
        <SlidesIndicator
          ref={slidesRef}
          options={{
            actualIndex,
            slidesCount: imagesCount,
            slideDuration: actualSlideDuration,
          }}
          changeIndex={changeIndexHandler}
        ></SlidesIndicator>
      </div>
    </div>
  );
};

export default ImagesCarousel;
