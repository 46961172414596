import { createSlice } from "@reduxjs/toolkit";

const slideshowSlice = createSlice({
  name: "slideshow",
  initialState: {
    actualSlideshowIndex: 0,
    actualSlideDuration: 6000,
  },
  reducers: {
    setIndex(state, action) {
      state.actualSlideshowIndex = action.payload.newIndex;
    },
    setSlideshowDuration(state, action) {
      state.actualSlideDuration = action.payload.slideDuration;
    },
  },
});

export const slideshowActions = slideshowSlice.actions;
export default slideshowSlice.reducer;
