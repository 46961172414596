export interface HttpConfig {
  baseUrl: string;
}

const prod: HttpConfig = {
  baseUrl: "https://api.katai.sk/api/v1",
};

const dev: HttpConfig = {
  baseUrl: "http://localhost:3000/api/v1",
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
