import classes from "./AdminOptions.module.scss";

interface AdminOptionsProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
}
const AdminOptions = (props: AdminOptionsProps) => {
  const handleEditClick = () => {
    props.onEditClick();
  };

  const handleDeleteClick = () => {
    props.onDeleteClick();
  };
  return (
    <div className={`${classes["admin-options"]} admin-options`}>
      <button
        className={`${classes["admin-button-white"]} classic-button`}
        onClick={handleEditClick}
      >
        <p className={"filter-text"}>Upraviť</p>
      </button>
      <button
        className={`${classes["admin-button-red"]} classic-button`}
        onClick={handleDeleteClick}
      >
        <p className={"filter-text"}>Odstrániť</p>
      </button>
    </div>
  );
};
export default AdminOptions;
