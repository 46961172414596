import classes from "./ModalSkeleton.module.scss";
import { createPortal } from "react-dom";
import { ReactElement } from "react";

const backdropOverlay = document.getElementById("overlays");
const Backdrop = ({ onClose }: { onClose: () => void }) => {
  return <div className={classes.backdrop} onClick={onClose}></div>;
};
const ModalSkeleton = ({
  children,
  onClose,
}: {
  children?: ReactElement;
  onClose: () => void;
}) => {
  const modalSkeletonJSX = (
    <div className={classes["modal-wrapper"]}>{children}</div>
  );

  return (
    <>
      {createPortal(<Backdrop onClose={onClose} />, backdropOverlay!)}
      {createPortal(modalSkeletonJSX, backdropOverlay!)}
    </>
  );
};

export default ModalSkeleton;
