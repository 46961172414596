import { useRef } from "react";
import classes from "./MainHeading.module.scss";
import { useWindowSize } from "@uidotdev/usehooks";
export interface MainHeadingProps {
  headingText: string;
  options?: {
    color?: string;
    lineWidth?: number;
    centeredLine?: boolean;
    fontSize?: string;
  };
}

const MainHeading = (props: MainHeadingProps) => {
  useWindowSize();
  const headingRef = useRef<HTMLHeadingElement | null>(null);

  const lineWidth = props.options?.lineWidth || 70;

  const dividerWidth =
    ((headingRef.current?.offsetWidth ? headingRef.current?.offsetWidth : 0) /
      100) *
    lineWidth;

  return (
    <div
      className={`${classes["heading-holder"]} ${
        props.options?.centeredLine ? classes.centered : ""
      } `}
    >
      <h1
        className="main-heading"
        style={{
          color: props.options?.color,
          fontSize: props.options?.fontSize,
        }}
        ref={headingRef}
      >
        {props.headingText}
      </h1>
      <div
        className={classes.divider}
        style={{ width: dividerWidth, backgroundColor: props.options?.color }}
      ></div>
    </div>
  );
};
export default MainHeading;
