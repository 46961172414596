import classes from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";
import Icons, { Icon } from "../icons/Icons";
import dotacia from '../../assets/documents/katai-dotacia.pdf';

//assets
import kataiLogoLight from "../../assets/images/logos/katai-white.png";
import kataiLogoDark from "../../assets/images/logos/katai-dark.png";
export enum ColorScheme {
  DARK,
  LIGHT,
}
interface FooterProps {
  type: ColorScheme;
}

const socialsArray = [
  {
    icon: Icon.FB,
    link: "https://www.facebook.com/budoklubkatai/",
  },
  {
    icon: Icon.IG,
    link: "https://www.instagram.com/katarinavargovcikova/",
  },
];

const routesArray = [
  {
    route: "/",
    name: "Úvod",
  },
  {
    route: "/sluzby",
    name: "Služby",
  },
  {
    route: "/treneri",
    name: "Tréneri",
  },
];
const Footer = (props: FooterProps) => {
  const navigate = useNavigate();

  const navigaitonHandler = (route: string) => {
    route === window.location.pathname
      ? window.scrollTo({ top: 0, behavior: "smooth" })
      : navigate(route);
  };

  return (
    <div
      className={`${classes["footer-holder"]} ${
        props.type === ColorScheme.LIGHT ? classes.light : ""
      }`}
    >
      <div className={`${classes["footer-content"]} classic-content`}>
        <h2 className={classes["footer-heading"]}>Budo Klub Katai</h2>
        <div className={classes["icons-row"]}>
          {socialsArray.map((item, index) => {
            return (
              <a
                key={index}
                href={item.link}
                target="_blank"
                className={classes.icon}
              >
                <Icons icon={item.icon} style={props.type} />
              </a>
            );
          })}
        </div>
        <div className={classes["info-holder"]}>
          <div className={`${classes["col"]} ${classes["navigation"]}`}>
            <ul className={classes["routes-list"]}>
              {routesArray.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      navigaitonHandler(item.route);
                    }}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
            <img
              className={classes.logo}
              src={
                props.type === ColorScheme.LIGHT
                  ? kataiLogoDark
                  : kataiLogoLight
              }
              alt="bkk logo"
            />
          </div>
          <div
            className={`${classes["col"]} ${classes["contact-information"]}`}
          >
            <div className={classes.group}>
              <a href="mailto:peterrusnak@centrum.cz">peterrusnak@centrum.cz</a>
              <a href="mailto: katka.vargovcikova@gmail.com">
                katka.vargovcikova@gmail.com{" "}
              </a>
            </div>
            <div className={classes.group}>
              <a href="tel:+421 907 901 894">+421 907 901 894</a>
              <a href="tel:+421 907 901 894">+421 905 243 568</a>
            </div>
            <a href="https://maps.app.goo.gl/dKUfLfwKqhEcq8bv8" target="_blank">
              Lomnická 10, 080 05, Prešov
            </a>
            <a href={dotacia} target='_blank'>Dotácia</a>
          </div>
          <div className={`${classes["col"]} ${classes["logo-section"]}`}>
            <img
              src={
                props.type === ColorScheme.DARK ? kataiLogoLight : kataiLogoDark
              }
              alt="BKK logo"
            />
          </div>
        </div>
        <div className={classes.copyright}>
          <p className={classes["footer-text"]}>
            created by{" "}
            <a
              href="https://www.linkedin.com/in/jakub-koles%C3%A1r-850787224/"
              target="_blank"
            >
              Jakub Kolesár
            </a>{" "}
            &copy;{(new Date()).getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
