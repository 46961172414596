import classes from './ProductCard.module.scss';

const ProductCardLoading = () => {
  const categoriesData = (
    <div className={classes.categories}>
      <div className={classes.holder}>
        <div className={`${classes.category} shine`}>
          <p className={classes["text-small"]}>Kardio</p>
        </div>
      </div>
      <div className={classes.holder}>
        <div className={`${classes.category} shine`}>
          <p className={classes["text-small"]}>Kardio</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes["content-holder"]}>
        <div className={`${classes['image-holder']} shine`}>
        </div>
        <div className={`${classes.holder} ${classes['title-holder']} shine`}>
          <h2 className={`${classes.title}`}>--------------</h2>
        </div>
        {categoriesData}
        <div className={`${classes["description-holder"]} ${classes.loading}`}>
          <div className={`${classes.holder} ${classes['description-holder']}`}>
            <p className={`${classes["text-normal"]} shine`}>------------------------------------------</p>
          </div>
          <div className={classes.holder}>
            <p className={`${classes["text-normal"]} shine`}>------------------------------</p>
          </div>
        </div>
        <div
          className={`${classes["service-row"]}`}
        >
          <div className={`${classes.holder} shine`}>
            <p className={`${classes["text-normal"]} ${classes.coaches}`}>
              ---------
            </p>
          </div>
          <div className={`${classes.holder} shine`}>
            <p className={`${classes["text-price"]} ${classes.title}`}>
              ------
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductCardLoading;