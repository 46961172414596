import kataiVideoSource_1 from "../../assets/videos/katai-home.mp4";
import { CarouselData } from "../../components/images-carousel/ImagesCarousel";

// carousel data
import kataiBuilding from "../../assets/images/homepage/katai-budova.jpg";
import kataiHore from "../../assets/images/homepage/katai-hore.jpg";
import kataiDole from "../../assets/images/homepage/katai-dole.jpg";
import kataiPredsien from "../../assets/images/homepage/katai-predsien.jpg";

// priority icons
import priorityIcon_1 from "../../assets/images/icons/priority_icon_1.svg";
import priorityIcon_2 from "../../assets/images/icons/priority_icon_2.svg";
import priorityIcon_3 from "../../assets/images/icons/priority_icon_3.svg";
import priorityIcon_4 from "../../assets/images/icons/priority_icon_4.svg";
import priorityIcon_5 from "../../assets/images/icons/priority_icon_5.svg";

// gallery
import karateSutaz from '../../assets/images/homepage/karate-sutaz.jpg';
import karate from '../../assets/images/homepage/karate.jpg';
import orientalneTance from '../../assets/images/homepage/orientalne-tance.jpg';
import spolocna1 from '../../assets/images/homepage/spolocna.jpg';
import spolocna2 from '../../assets/images/homepage/spolocna-1.jpg';
import tabata from '../../assets/images/homepage/tabata.jpg';
import karateFamily from '../../assets/images/homepage/karate-family.jpg';
import kataiDiplom from '../../assets/images/homepage/katai-diplom.jpg';
import orientalneTance2 from '../../assets/images/homepage/orientalne-tance-2.jpg';
import peterKarate from '../../assets/images/homepage/peter-karate.jpeg';
import kataiMemorial from '../../assets/images/homepage/katai-memorial.jpg';

const kataiVideo_1 = {
  src: kataiVideoSource_1,
  type: "video/mp4",
};
export const carouselImages: CarouselData[] = [
  {
    id: 1,
    video: kataiVideo_1,
    title: "Budo Klub Katai",
    description:
      "Športové centrum pre rozvoj Vašich športových a tanečných aktivít v každom veku.",
  },
  {
    id: 2,
    image: kataiBuilding,
    title: "Budo Klub Katai",
    description:
      "Športové centrum pre rozvoj Vašich športových a tanečných aktivít v každom veku.",
  },
  {
    id: 3,
    image: kataiHore,
    title: "Budo Klub Katai",
    description:
      "Športové centrum pre rozvoj Vašich športových a tanečných aktivít v každom veku.",
  },
  {
    id: 4,
    image: kataiDole,
    title: "Budo Klub Katai",
    description:
      "Športové centrum pre rozvoj Vašich športových a tanečných aktivít v každom veku.",
  },
  {
    id: 5,
    image: kataiPredsien,
    title: "Budo Klub Katai",
    description:
      "Športové centrum pre rozvoj Vašich športových a tanečných aktivít v každom veku.",
  },
];

export const prioritiesArray = [
  {
    img: priorityIcon_1,
    text: "Rozvoj športových\na fyzických zdatností členov",
  },
  {
    img: priorityIcon_2,
    text: "Zdokonaľovanie sa\nv bojových umeniach",
  },
  {
    img: priorityIcon_3,
    text: "Organizovanie a účasť\nna športových súťažiach",
  },
  {
    img: priorityIcon_4,
    text: "Prevádzkovanie posilňovne\na fitnescentra",
  },
  {
    img: priorityIcon_5,
    text: "Organizovanie športových\na kulturných podujatí",
  },
];

/*
export interface GalleryData {
  [key: string | number]: {
    aspectRatio: string;
  }[];
}
*/
export interface GalleryData {
  colIndex?: number;
  aspectRatio?: string;
  src?: string;
}
export const galleryArray: GalleryData[] = [
  {
    colIndex: 0,
    aspectRatio: "9/11",
    src: karateSutaz
  },
  {
    colIndex: 0,
    aspectRatio: "16/11",
    src: spolocna1
  },
  {
    colIndex: 0,
    aspectRatio: "9/11",
    src: kataiDiplom
  },
  {
    colIndex: 1,
    aspectRatio: "16/11",
    src: karate
  },
  {
    colIndex: 1,
    aspectRatio: "1/1",
    src: tabata
  },
  {
    colIndex: 1,
    aspectRatio: "16/11",
    src: spolocna2
  },
  {
    colIndex: 1,
    aspectRatio: "16/12",
    src: orientalneTance2
  },
  {
    colIndex: 1,
    aspectRatio: "16/11",
    src: kataiMemorial
  },
  {
    colIndex: 2,
    aspectRatio: "9/11",
    src: orientalneTance
  },
  {
    colIndex: 2,
    aspectRatio: "9/11",
    src: karateFamily
  },
  {
    colIndex: 2,
    aspectRatio: "16/11",
    src: peterKarate
  },
];