export enum AdminModalType {
  ADD_COACH = "Pridať trenéra",
  EDIT_COACH = "Upraviť trenéra",
  DELETE_COACH = "Vymazať trenéra",
  ADD_SERVICE = "Pridať službu",
  EDIT_SERVICE = "Upraviť službu",
  DELETE_SERVICE = "Vymazať službu",
  ADD_PRODUCT = "Pridať produkt",
  EDIT_PRODUCT = "Upraviť produkt",
  ADD_CATEGORY = "Pridať kategóriu",
  EDIT_CATEGORY = "Editovať kategóriu",
  DELETE_CATEGORY = "Vymazať kategóriu",
  EDIT_DELETE_CATEGORY = "Upraviť kategórie",
  DELETE_MODAL = "Ste si istí?",
}
