import classes from "./KataiLogin.module.scss";
import kataiLogo from "../../assets/images/logos/katai-white.png";
import { useEffect, useState } from "react";
import { AuthAPI } from "../../api/services/AuthAPI";
import { message } from "antd";
import { LocalStorageKeys } from "../../api/enums/localStorage-keys";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { adminActions } from "../../store/admin-slice";
import {refreshToken} from "../../api/configs/api-config";
import useDocumentTitle from "../../hooks/use-document-title";
const KataiLogin = () => {
  useDocumentTitle({title: 'Budo Klub Katai - Prihlásenie'});
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = useState(false);
  const [formValue, setFormValue] = useState({
    login: "",
    password: "",
  });

  const onChange = (field: string, value: string) => {
    setFormValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const loginHandler = async (event: any) => {
    event.preventDefault();
    try {
      const token = await AuthAPI.login(formValue);
      setIsValid(false);
      localStorage.setItem(LocalStorageKeys.ID_TOKEN, token);
      refreshToken();
      dispatch(adminActions.setIsAdmin({ isAdmin: true }));
      messageApi.open({
        type: "success",
        content: "Prihlásenie prebehlo úspešne, onedlho budete presmerovaní.",
      });
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (err: any) {
      messageApi.open({
        type: "error",
        content: err,
      });
    }
  };

  useEffect(() => {
    setIsValid(formValue.login.length > 0 && formValue.password.length > 0);
  }, [formValue]);
  return (
    <>
      {contextHolder}
      <div className={classes.wrapper}>
        <form className={classes.form}>
          <img
            className={classes["katai-logo"]}
            src={kataiLogo}
            alt="budo klub katai logo"
          />
          <div className={classes.inputs}>
            <input
              type="text"
              placeholder={"Login"}
              className={`${formValue.login.length > 0 ? classes.valid : ""}`}
              onChange={(event) => {
                onChange("login", event.target.value);
              }}
              value={formValue.login}
            />
            <input
              type="password"
              placeholder={"Heslo"}
              className={`${
                formValue.password.length > 0 ? classes.valid : ""
              }`}
              onChange={(event) => {
                onChange("password", event.target.value);
              }}
              value={formValue.password}
            />
            <button
              className={`${classes["submit-button"]} ${
                !isValid ? classes.invalid : ""
              }`}
              onClick={loginHandler}
            >
              Prihlásiť sa
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default KataiLogin;
