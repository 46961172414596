import axios from "../configs/api-config";
export const CoachesImagesAPI = {
  uploadImage: async (id: string, image: File) => {
    let bodyFormData = new FormData();
    bodyFormData.set("coach_image", image);

    try {
      const { data } = await axios.request({
        method: "POST",
        url: `/coaches/${id}/coach-image`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      });
      return data as string;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  deleteImage: async (id: string) => {
    try {
      await axios.request({
        method: "DELETE",
        url: `/coaches/${id}/coach-image`,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
};
