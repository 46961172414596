import { configureStore } from "@reduxjs/toolkit";
import slideshowReducer from "./slideshow-slice";
import adminReducer from "./admin-slice";

const store = configureStore({
  reducer: { slideshow: slideshowReducer, admin: adminReducer },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
