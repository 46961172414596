import { FormProps } from "./form-props";
import classes from "./Forms.module.scss";
import BasicInput from "../../inputs/basic-input/BasicInput";
import { useEffect, useRef, useState } from "react";
import { FormValues } from "./form-data";
import { Platform } from "../../../api/models/platform";
import ImageInput from "../../inputs/image-input/ImageInput";
import { Coach } from "../../../api/models/coach";
import { Service } from "../../../api/models/service";
import { Category } from "../../../api/models/category";
import Dropdown, {
  DropdownData,
} from "../../filter-components/dropdown/Dropdown";
import DaysInput from "../../inputs/days-input/DaysInput";
import {
  DayInputRef,
  DropdownRef,
} from "../../filter-components/filter-component";
import crossIcon from "../../../assets/images/icons/cross-icon.svg";
import { DayTime } from "../../../api/models/day-time";
import { Day } from "../../../api/models/day";
import { CategoriesAPI } from "../../../api/services/CategoriesAPI";
import { DaysAPI } from "../../../api/services/DaysAPI";
import { CoachesAPI } from "../../../api/services/CoachesAPI";

export const checkServiceValidity = (formData: FormValues) => {
  return (
    formData.title?.length > 0 &&
    formData.price?.length > 0 &&
    formData.description?.length > 0
  );
};
const AddEditCoach = (props: FormProps) => {
  let data = props.data as Service;
  const coachesDropdown = useRef<DropdownRef>(null);
  const categoriesDropdown = useRef<DropdownRef>(null);
  const daysInput = useRef<DayInputRef>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownsData, setDropdownsData] = useState({
    coaches: [] as Coach[],
    categories: [] as Category[],
    days: [] as Day[],
  });
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<FormValues>({
    title: data?.title || "",
    price: data?.price || "",
    description: data?.description || "",
    feature_image_url: data?.feature_image_url || null,
    categories: data?.categories?.map((item) => item.category_id) || [],
    coaches: data?.coaches?.map((coach) => coach.coach_id) || [],
    days:
      data?.days?.map((day) => ({
        day_id: day.day_id,
        time_from: day.time_from,
        time_to: day.time_to,
      })) || [],
  });

  const onChangeHandler = (propertyName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  const handleCoachSelection = (coachesArray: DropdownData[]) => {
    setFormData((prevState) => ({
      ...prevState,
      coaches: coachesArray.map((item) => item.id),
    }));
  };

  const handleCategorySelection = (categoriesArray: DropdownData[]) => {
    setFormData((prevState) => ({
      ...prevState,
      categories: categoriesArray.map((item) => item.id),
    }));
  };
  const addDayHandler = (day: DayTime) => {
    let daysArray: any[] = [];
    if (formData.days) {
      daysArray = formData.days;
    }
    daysArray.push(day);
    setFormData((prevState) => ({
      ...prevState,
      days: daysArray,
    }));
    if (daysInput.current) {
      daysInput.current.reset();
    }
  };

  const deleteDayHandler = (id: number) => {
    setFormData((prevState) => ({
      ...prevState,
      days: prevState.days.filter((day: DayTime) => day.day_id !== id),
    }));
  };

  useEffect(() => {
    props.onFormValidityChange(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsValid(checkServiceValidity(formData));
    props.onFormValueChange(formData);
  }, [formData]);

  // data fetching
  useEffect(() => {
    const fetchArray = [
      CategoriesAPI.getAll(),
      DaysAPI.getAll(),
      CoachesAPI.getAll(),
    ];

    Promise.all(fetchArray)
      .then(([categories, days, coaches]) => {
        setDropdownsData({
          coaches: coaches as Coach[],
          categories: categories as Category[],
          days: days as Day[],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (coachesDropdown.current && formData.coaches) {
      coachesDropdown.current.setValue(formData.coaches);
    }
    if (categoriesDropdown.current && formData.categories) {
      categoriesDropdown.current.setValue(formData.categories);
    }
  }, [isLoading]);

  return (
    <form className={`${classes.form}`}>
      <div className={classes.group}>
        <div className={classes.row}>
          <BasicInput
            placeholder={"Názov"}
            required={true}
            onChange={(value) => {
              onChangeHandler("title", value);
            }}
            value={formData.title}
          />
          <BasicInput
            type={"text"}
            placeholder={"Cena"}
            required={true}
            onChange={(value) => {
              onChangeHandler("price", value);
            }}
            value={formData.price}
          />
        </div>
        <div className={classes.row}>
          <BasicInput
            placeholder={"Popis"}
            required={true}
            onChange={(value) => {
              onChangeHandler("description", value);
            }}
            value={formData.description}
          />
        </div>
      </div>
      <div className={classes.group}>
        <h2 className={classes.heading}>Tréneri</h2>
        <Dropdown
          placeholder={"Vyberte trenérov"}
          data={dropdownsData.coaches.map((coach) => ({
            id: coach.coach_id,
            title: coach.name!,
          }))}
          ref={coachesDropdown}
          onSelection={handleCoachSelection}
          isModalDropdown={true}
          selectMultiple={true}
        />
      </div>
      <div className={classes.group}>
        <h2 className={classes.heading}>Kategórie</h2>
        <Dropdown
          placeholder={"Vyberte kategórie"}
          data={dropdownsData.categories.map((category) => ({
            id: category.category_id,
            title: category.name,
          }))}
          ref={categoriesDropdown}
          onSelection={handleCategorySelection}
          isModalDropdown={true}
          selectMultiple={true}
        />
      </div>
      <div className={classes.group}>
        <h2 className={classes.heading}>Kedy sa služba vykonáva?</h2>
        {formData.days?.length > 0 && (
          <ul className={classes.list}>
            {formData.days?.map((item: DayTime) => {
              return (
                <li className={classes["list-item"]} key={item.day_id}>
                  <img
                    className={classes.cross}
                    src={crossIcon}
                    alt="cross"
                    onClick={() => {
                      deleteDayHandler(item.day_id);
                    }}
                  />
                  <p className={classes.platform}>
                    {
                      dropdownsData.days.find(
                        (day) => day.day_id === item.day_id
                      )?.name
                    }{" "}
                    od {item.time_from?.substring(0, 5)} do{" "}
                    {item.time_to?.substring(0, 5)}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
        <DaysInput
          days={dropdownsData.days}
          onDayAdd={addDayHandler}
          ref={daysInput}
        />
      </div>
      <div className={classes.group}>
        <ImageInput
          onChange={(image) => {
            if (image) {
              onChangeHandler("featureImage", image);
            } else {
              onChangeHandler("featureImage", null);
            }
          }}
          value={formData?.feature_image_url}
        />
      </div>
    </form>
  );
};
export default AddEditCoach;
