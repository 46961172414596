import axios from "../configs/api-config";
import { LocalStorageKeys } from "../enums/localStorage-keys";
export const AuthAPI = {
  login: async (formData: { login: string; password: string }) => {
    try {
      const { data } = await axios.request({
        method: "POST",
        url: "/auth",
        data: formData,
      });

      return data as string;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  getMe: async (token: string) => {
    try {
      const { data } = await axios.request({
        method: "GET",
        url: "/auth/me",
        headers: {
          "x-auth-token": token,
        },
      });
      return data;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  logOut: () => {
    localStorage.removeItem(LocalStorageKeys.ID_TOKEN);
  },
};
