import {AdminModalType} from "./admin-modal-type";
import ModalSkeleton from "../modal-skeleton/ModalSkeleton";
import classes from "./AdminModals.module.scss";
import MainHeading from "../main-heading/MainHeading";
import {useRef, useState} from "react";
import AddCategory from "./forms/AddCategory";
import {FormValues} from "./forms/form-data";
import EditDeleteCategory, {
  CategoryModalRef,
} from "./forms/EditDeleteCategory";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import AddEditCoach from "./forms/AddEditCoach";
import {adminActions} from "../../store/admin-slice";
import AddEditService from "./forms/AddEditService";
import AddEditProduct from "./forms/AddEditProduct";
import {ServicesAPI} from "../../api/services/ServicesAPI";
import {ServicesImagesAPI} from "../../api/services/ServicesImagesAPI";
import {CategoriesAPI} from "../../api/services/CategoriesAPI";
import {CoachesAPI} from "../../api/services/CoachesAPI";
import {CoachesImagesAPI} from "../../api/services/CoachesImagesAPI";

interface AdminModalsProps {
  onSuccess: (message: string, type: AdminModalType, data: any) => void;
  onError: (message: string) => void;
}

const AdminModals = (props: AdminModalsProps) => {
  const {
    modalOptions: {modalType, modalData},
  } = useAppSelector((state) => state.admin);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<FormValues>({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const categoryModalRef = useRef<CategoryModalRef>(null);
  const closeModalHandler = () => {
    dispatch(adminActions.closeModal());
  };

  const formValidityChangeHandler = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const formValueChangeHandler = (formValue: FormValues) => {
    setFormData(formValue);
  };

  const uploadFeature = async (id: string, file: File) => {
    try {
      return await ServicesImagesAPI.uploadFeature(id, file);
    } catch (err) {
      throw err;
    }
  };

  const uploadCoachImage = async (id: string, file: File) => {
    try {
      return await CoachesImagesAPI.uploadImage(id, file);
    } catch (err) {
      throw err;
    }
  };
  const deleteCoachImage = async (id: string) => {
    try {
      await CoachesImagesAPI.deleteImage(id);
    } catch (err) {
      throw err;
    }
  };
  const deleteFeature = async (id: string) => {
    try {
      await ServicesImagesAPI.deleteFeature(id);
    } catch (err) {
      throw err;
    }
  };
  const onFormSubmitHandler = async () => {
    setIsLoading(true);
    const axiosData = formData;
    const {featureImage, category_id, coachImage} = axiosData;
    delete axiosData.category_id;
    delete axiosData.feature_image_url;
    delete axiosData.coach_image_url;
    delete axiosData.featureImage;
    delete axiosData.coachImage;

    switch (modalType) {
      case AdminModalType.ADD_SERVICE:
      case AdminModalType.ADD_PRODUCT: {
        try {
          const data = await ServicesAPI.create(axiosData);

          if (data.service_id && featureImage) {
            const featureUrl = await uploadFeature(
              data.service_id,
              featureImage
            );
            data.feature_image_url = featureUrl;
          }

          props.onSuccess("Služba bola úspešne vytvorená", modalType, data);
          dispatch(adminActions.closeModal());
        } catch (err: any) {
          props.onError(err);
        } finally {
          setIsLoading(false);
        }
        break;
      }
      case AdminModalType.EDIT_SERVICE:
      case AdminModalType.EDIT_PRODUCT: {
        if (modalData && "service_id" in modalData && modalData.service_id) {
          try {
            // 1 undefined -> no change occured
            // 1 null -> user deleted the image
            // 0 undefined -> no change occured
            // 0 file -> user wants to upload an image
            // 1 file -> user wants to change the image

            if (modalData.feature_image_url && featureImage === null) {
              await deleteFeature(modalData.service_id);
            } else if (!modalData.feature_image_url && featureImage) {
              await uploadFeature(modalData.service_id, featureImage);
            } else if (modalData.feature_image_url && featureImage) {
              await deleteFeature(modalData.service_id);
              await uploadFeature(modalData.service_id, featureImage);
            }

            const data = await ServicesAPI.edit(
              axiosData,
              modalData.service_id
            );

            props.onSuccess("Služba bola úspešne upravená", modalType, data);
            dispatch(adminActions.closeModal());
          } catch (err: any) {
            props.onError(err);
          } finally {
            setIsLoading(false);
          }
        }
        break;
      }
      case AdminModalType.ADD_CATEGORY: {
        try {
          const data = await CategoriesAPI.create(formData);
          props.onSuccess("Kategória bola úspešne vytvorená", modalType, data);
          dispatch(adminActions.closeModal());
        } catch (err: any) {
          props.onError(err);
        } finally {
          setIsLoading(false)
        }
        break;
      }
      case AdminModalType.EDIT_DELETE_CATEGORY: {
        try {
          const data = await CategoriesAPI.edit(axiosData, category_id);
          categoryModalRef.current?.reset();
          props.onSuccess(
            "Kategória bola úspešne upravená",
            AdminModalType.EDIT_CATEGORY,
            data
          );
        } catch (err: any) {
          props.onError(err);
        } finally {
          setIsLoading(false);
        }
        break;
      }
      case AdminModalType.ADD_COACH: {
        try {
          const data = await CoachesAPI.create(axiosData);
          if (coachImage) {
            const imageUrl = await uploadCoachImage(data.coach_id, coachImage);
            data.coach_image_url = imageUrl;
          }
          props.onSuccess("Trenér bol úspešne vytvorený", modalType, data);
          dispatch(adminActions.closeModal());
        } catch (err: any) {
          props.onError(err);
        } finally {
          setIsLoading(false);
        }
        break;
      }
      case AdminModalType.EDIT_COACH: {
        if (modalData && "coach_id" in modalData && modalData.coach_id) {
          try {
            // 1 undefined -> no change occured
            // 1 null -> user deleted the image
            // 0 undefined -> no change occured
            // 0 file -> user wants to upload an image
            // 1 file -> user wants to change the image

            if (modalData.coach_image_url && coachImage === null) {
              await deleteCoachImage(modalData.coach_id);
            } else if (!modalData.coach_image_url && coachImage) {
              await uploadCoachImage(modalData.coach_id, coachImage);
            } else if (modalData.coach_image_url && coachImage) {
              await deleteCoachImage(modalData.coach_id);
              await uploadCoachImage(modalData.coach_id, coachImage);
            }

            const data = await CoachesAPI.edit(axiosData, modalData.coach_id);

            props.onSuccess("Trenér bol úspešne upravený", modalType, data);
            dispatch(adminActions.closeModal());
          } catch (err: any) {
            props.onError(err);
          } finally {
            setIsLoading(false);
          }
        }
        break;
      }
    }
  };

  const onDeleteHandler = async (id?: any) => {
    if (id) {
      try {
        await CategoriesAPI.delete(id);
        categoryModalRef.current?.reset();
        props.onSuccess(
          "Kategória bola úspešne vymazaná",
          AdminModalType.DELETE_CATEGORY,
          id
        );
      } catch (err: any) {
        props.onError(err);
      }
    } else if (
      modalData &&
      "type" in modalData &&
      "id" in modalData &&
      modalData.id
    ) {
      switch (modalData.type) {
        case AdminModalType.DELETE_SERVICE: {
          try {
            await ServicesAPI.delete(modalData.id);
            props.onSuccess(
              "Služba bola úspešne odstránená",
              AdminModalType.DELETE_SERVICE,
              modalData.id
            );
            dispatch(adminActions.closeModal());
          } catch (err: any) {
            props.onError(err);
          }
          break;
        }
        case AdminModalType.DELETE_COACH: {
          try {
            await CoachesAPI.delete(modalData.id);
            props.onSuccess(
              "Trenér bol úspešne odstránený",
              AdminModalType.DELETE_COACH,
              modalData.id
            );
            dispatch(adminActions.closeModal());
          } catch (err: any) {
            props.onError(err);
          }
          break;
        }
      }
    }
  };

  const modalButtons = (
    <div className={`${classes["modal-buttons"]}`}>
      <button className={classes["cancel-button"]} onClick={closeModalHandler}>
        <p>Zrušiť</p>
      </button>
      {modalType === AdminModalType.EDIT_DELETE_CATEGORY && (
        <button
          className={`${classes["delete-button"]} ${
            !formData.category_id || isLoading ? classes.invalid : ""
          }`}
          onClick={() => {
            onDeleteHandler(formData.category_id);
          }}
        >
          <p>Odstrániť</p>
        </button>
      )}
      {modalType !== AdminModalType.DELETE_MODAL && (
        <button
          className={`${classes["confirm-button"]} ${
            !isFormValid || isLoading ? classes.invalid : ""
          }`}
          onClick={onFormSubmitHandler}
        >
          <p>Uložiť</p>
        </button>
      )}
      {modalType === AdminModalType.DELETE_MODAL && (
        <button
          className={`${classes["confirm-button"]} ${
            isLoading ? classes.invalid : ""
          }`}
          onClick={() => {
            onDeleteHandler();
          }}
        >
          <p>Odstrániť</p>
        </button>
      )}
    </div>
  );

  const modalContent = (
    <>
      <div className={classes["title-holder"]}>
        <MainHeading
          headingText={modalType}
          options={{color: "var(--dark-text-color)", fontSize: "36px"}}
        />
      </div>
      <div className={classes["content-holder"]}>
        {modalType === AdminModalType.ADD_CATEGORY && (
          <AddCategory
            onFormValueChange={formValueChangeHandler}
            onFormValidityChange={formValidityChangeHandler}
          />
        )}
        {modalType === AdminModalType.EDIT_DELETE_CATEGORY && (
          <EditDeleteCategory
            ref={categoryModalRef}
            onFormValueChange={formValueChangeHandler}
            onFormValidityChange={formValidityChangeHandler}
          />
        )}
        {(modalType === AdminModalType.ADD_COACH ||
          modalType === AdminModalType.EDIT_COACH) && (
          <AddEditCoach
            data={modalData}
            onFormValueChange={formValueChangeHandler}
            onFormValidityChange={formValidityChangeHandler}
          />
        )}
        {(modalType === AdminModalType.ADD_SERVICE ||
          modalType === AdminModalType.EDIT_SERVICE) && (
          <AddEditService
            data={modalData}
            onFormValueChange={formValueChangeHandler}
            onFormValidityChange={formValidityChangeHandler}
          />
        )}
        {(modalType === AdminModalType.ADD_PRODUCT ||
          modalType === AdminModalType.EDIT_PRODUCT) && (
          <AddEditProduct
            data={modalData}
            onFormValueChange={formValueChangeHandler}
            onFormValidityChange={formValidityChangeHandler}
          />
        )}
        {modalType === AdminModalType.DELETE_MODAL && (
          <p className={classes.description}>{modalData?.description}</p>
        )}
      </div>
      {modalButtons}
    </>
  );

  return (
    <ModalSkeleton onClose={closeModalHandler}>{modalContent}</ModalSkeleton>
  );
};
export default AdminModals;
