import classes from './CoachCard.module.scss';

const CoachCardLoading = () => {

  return (
    <div className={classes.wrapper}>
      <div className={classes["content-holder"]}>
        <div className={classes["image-wrapper"]}>
          <div className={`${classes["image-holder"]} shine`}></div>
        </div>
        <div className={classes["info-holder"]}>
          <div className={classes.holder}>
            <h2 className={`${classes.title} shine`}>
              -------
            </h2>
          </div>
          <div className={`${classes["description-holder"]} ${classes.loading} light-scrollbar`}>
            <div className={classes.holder}>
              <p className={`${classes.description} shine`}>-----------------</p>
            </div>
            <div className={classes.holder}>
              <p className={`${classes.description} shine`}>-------------</p>
            </div>
          </div>
          <div className={classes["socials-row"]}>
            <div className={classes.holder}>
              <div className={`${classes.icon} shine`}></div>
            </div>
            <div className={classes.holder}>
              <div className={`${classes.icon} shine`}></div>
            </div>
            <div className={classes.holder}>
              <div className={`${classes.icon} shine`}></div>
            </div>
            <div className={classes.holder}>
              <div className={`${classes.icon} shine`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CoachCardLoading;