import classes from "./ProductCard.module.scss";
import { Service } from "../../api/models/service";
import { useAppSelector } from "../../store/hooks";
import ImagePlaceholder from "../image-placeholder/ImagePlaceholder";
import { ColorScheme } from "../footer/Footer";
import AdminOptions from "../admin-options/AdminOptions";
import { useDispatch } from "react-redux";
import { adminActions } from "../../store/admin-slice";
import { AdminModalType } from "../admin-modals/admin-modal-type";

interface ProductCardProps {
  data: Service;
}
const ProductCard = (props: ProductCardProps) => {
  const { isAdmin } = useAppSelector((store) => store.admin);
  const dispatch = useDispatch();
  const onDeleteHandler = () => {
    dispatch(
      adminActions.openModal({
        modalType: AdminModalType.DELETE_MODAL,
        modalData: {
          id: props.data.service_id,
          type: AdminModalType.DELETE_SERVICE,
          description: `Naozaj chcete vymazať ${
            props.data.is_product ? "produkt" : "službu"
          } ${props.data.title}?`,
        },
      })
    );
  };
  const onEditHandler = () => {
    const modalData = props.data;
    delete modalData.created_at;
    dispatch(
      adminActions.openModal({
        modalType: props.data.is_product
          ? AdminModalType.EDIT_PRODUCT
          : AdminModalType.EDIT_SERVICE,
        modalData,
      })
    );
  };

  const serviceImage = props.data.feature_image_url ? (
    <img
      src={props.data.feature_image_url}
      alt={`${props.data.title} feature`}
    />
  ) : (
    <ImagePlaceholder type={ColorScheme.DARK} />
  );
  const daysData = (
    <div className={classes.times}>
      {props.data.days?.map((day) => {
        return (
          <div className={classes.time} key={day.day_id}>
            <p className={classes["text-small"]}>
              {day?.name?.slice(0, 2).toUpperCase()}{" "}
              {day.time_from?.substring(0, 5)}
            </p>
          </div>
        );
      })}
    </div>
  );

  const categoriesData = (
    <div className={classes.categories}>
      {props.data.categories?.map((category) => {
        return (
          <div className={classes.category} key={category.category_id}>
            <p className={classes["text-small"]}>{category.name}</p>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {isAdmin && (
        <AdminOptions
          onDeleteClick={onDeleteHandler}
          onEditClick={onEditHandler}
        />
      )}
      <div className={classes["content-holder"]}>
        <div className={classes["image-holder"]}>
          <div className={classes["image-content"]}>
            {serviceImage}
            {props.data.days && props.data.days.length > 0 && daysData}
          </div>
        </div>
        <h2 className={classes.title}>{props.data.title}</h2>
        {props.data.categories &&
          props.data.categories.length > 0 &&
          categoriesData}
        <div className={classes["description-holder"]}>
          <p className={classes["text-normal"]}>{props.data.description}</p>
        </div>
        <div
          className={`${classes["service-row"]} ${
            props.data.coaches && props.data.coaches.length === 0
              ? classes["justify-end"]
              : ""
          }`}
        >
          {props.data.coaches && props.data.coaches.length > 0 && (
            <p className={`${classes["text-normal"]} ${classes.coaches}`}>
              by {props.data.coaches?.map((coach) => coach.name).join(", ")}
            </p>
          )}
          <p className={`${classes["text-price"]} ${classes.title}`}>
            {props.data.price}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
