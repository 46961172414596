import { FormProps } from "./form-props";
import classes from "./Forms.module.scss";
import BasicInput from "../../inputs/basic-input/BasicInput";
import { useEffect, useState } from "react";
import { FormValues } from "./form-data";
import crossIcon from "../../../assets/images/icons/cross-icon.svg";
import { Platform } from "../../../api/models/platform";
import PlatformsInput from "../../inputs/platforms-input/PlatformsInput";
import { PlatformInput } from "../../../api/models/platform-input";
import ImageInput from "../../inputs/image-input/ImageInput";
import { Coach } from "../../../api/models/coach";

const platformsArray: Platform[] = [
  {
    platform_id: "3f7d9ec6-0fdf-4f10-ba57-07f2d8e3b143",
    platform_key: "fb",
  },
  {
    platform_id: "4e7e8e61-bd38-4510-a8ff-db5f40c1984b",
    platform_key: "ig",
  },
  {
    platform_id: "6da169c4-6331-4bd0-bfb3-9b8fcc3305d3",
    platform_key: "email",
  },
  {
    platform_id: "3f2a006c-6af9-4601-8263-92e22ba4f7ff",
    platform_key: "phone",
  },
];
const AddEditCoach = (props: FormProps) => {
  const [isValid, setIsValid] = useState(false);
  let data = props.data as Coach;
  const [formData, setFormData] = useState<FormValues>({
    name: data?.name || "",
    description: data?.description || "",
    socials:
      data?.socials?.map((item) => ({
        platform_key: item.platform_key,
        platform_value: item.platform_value,
      })) || [],
    coach_image_url: data?.coach_image_url || null,
  });
  const onChangeHandler = (propertyName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  const deletePlatformHandler = (platformKey: string) => {
    let socialsArray: any[] = formData.socials.filter(
      (item: any) => item.platform_key !== platformKey
    );
    setFormData((prevState) => ({
      ...prevState,
      socials: socialsArray,
    }));
  };

  const addPlatformHandler = (platform: PlatformInput) => {
    let platforms: any[] = [];
    if (formData?.socials) {
      platforms = formData.socials;
    }
    platforms.push(platform);
    setFormData((prevState) => ({
      ...prevState,
      socials: platforms,
    }));
  };
  const checkValidity = () => {
    if (formData.name?.length > 0 && formData.description?.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    props.onFormValidityChange(isValid);
  }, [isValid]);

  useEffect(() => {
    checkValidity();
    props.onFormValueChange(formData);
  }, [formData]);

  return (
    <form className={`${classes.form}`}>
      <div className={classes.group}>
        <BasicInput
          placeholder={"Meno"}
          required={true}
          onChange={(value) => {
            onChangeHandler("name", value);
          }}
          value={formData.name}
        />
        <BasicInput
          placeholder={"Popis"}
          required={true}
          onChange={(value) => {
            onChangeHandler("description", value);
          }}
          value={formData.description}
        />
      </div>
      <div className={classes.group}>
        <h2 className={classes.heading}>Kontakt</h2>
        {formData?.socials?.length > 0 && (
          <ul className={classes.list}>
            {formData.socials.map((item: any) => {
              return (
                <li className={classes["list-item"]} key={item.platform_key}>
                  <img
                    className={classes.cross}
                    src={crossIcon}
                    alt="cross"
                    onClick={() => {
                      deletePlatformHandler(item.platform_key);
                    }}
                  />
                  <p className={classes.platform}>{item.platform_key}</p>
                  <p>{item.platform_value}</p>
                </li>
              );
            })}
          </ul>
        )}
        <PlatformsInput
          platformsArray={platformsArray
            .map((item) => ({
              id: item.platform_key!,
              title: item.platform_key!,
            }))
            .filter(
              (item) =>
                !formData?.socials?.some(
                  (socialItem: Platform) => socialItem.platform_key === item.id
                )
            )}
          onPlatformAdd={addPlatformHandler}
        />
      </div>
      <div className={classes.group}>
        <ImageInput
          onChange={(image) => {
            if (image) {
              onChangeHandler("coachImage", image);
            } else {
              onChangeHandler("coachImage", null);
            }
          }}
          value={formData?.coach_image_url}
        />
      </div>
    </form>
  );
};
export default AddEditCoach;
