import axios from "../configs/api-config";
import { Coach } from "../models/coach";
export const CoachesAPI = {
  getAll: async () => {
    const { data } = await axios.request({
      method: "GET",
      url: "/coaches",
    });
    return data as Coach[];
  },
  create: async (formData: any) => {
    try {
      const { data } = await axios.request({
        url: "/coaches",
        method: "POST",
        data: formData,
      });
      return data as Coach;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  edit: async (formData: any, id: string) => {
    try {
      const { data } = await axios.request({
        method: "PUT",
        url: `/coaches/${id}`,
        data: formData,
      });
      return data as Coach;
    } catch (err: any) {
      throw err.response.data;
    }
  },
  delete: async (id: string) => {
    try {
      await axios.request({
        method: "DELETE",
        url: `/coaches/${id}`,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  },
};
