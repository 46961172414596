import { useRef, useEffect } from 'react'

interface useDocumentTitleProps {
  title: string,
  prevailOnUnmount?:boolean
}
function useDocumentTitle(props : useDocumentTitleProps) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  useEffect(() => () => {
    if (!props.prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle