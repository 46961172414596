import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import HomePage from "./pages/homepage/HomePage";
import Services from "./pages/services/Services";
import Coaches from "./pages/coaches/Coaches";
import KataiLogin from "./pages/katai-login/KataiLogin";
const router = createBrowserRouter([
  // ROOT LAYOUT ROUTES
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "sluzby",
        element: <Services />,
      },
      {
        path: "treneri",
        element: <Coaches />,
      },
    ],
  },
  // NON ROOT LAYOUT ROUTES
  {
    path: "/katai-login",
    element: <KataiLogin />,
  },
]);

export const darkFooterPaths = ["/"];

function App() {
  return <RouterProvider router={router} />;
}

export default App;
