import { useEffect, useState } from "react";
import { FilterSettings } from "../components/filter-components/filter-settings";
import useQuery from "./useQuery";
import { URLParams } from "../components/filter-components/url-params";
import { createSearchParams, useNavigate } from "react-router-dom";

export const DEFAULT_FILTER_SETTINGS: FilterSettings = {
  search: "",
  categories: [],
  days: [],
  coaches: [],
};
const useFilterQueryParams = () => {
  const navigate = useNavigate();
  const [filterSettings, setFilterSettings] = useState(DEFAULT_FILTER_SETTINGS);
  const query = useQuery();

  // handle params on init
  useEffect(() => {
    let newObj: FilterSettings;
    const search = query.get(URLParams.SEARCH);
    const categories = query.get(URLParams.CATEGORIES);
    const days = query.get(URLParams.DAYS);
    const coaches = query.get(URLParams.COACHES);

    newObj = {
      search: search || "",
      categories: categories ? categories.split(",") : [],
      days: days ? days.split(",").map((item) => +item) : [],
      coaches: coaches ? coaches.split(",") : [],
    };

    setFilterSettings(newObj);
  }, [query]);

  const setQuery = (param: URLParams, value: any) => {
    const queryParams = new URLSearchParams(window.location.search);

    if (value.length > 0) {
      if (Array.isArray(value)) {
        queryParams.set(param, value.map((item) => item.id).join(","));
      } else {
        queryParams.set(param, value);
      }
    } else {
      queryParams.delete(param);
    }

    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  const reset = () => {
    navigate("", { replace: true });
  };

  return { filterSettings, setQuery, reset };
};

export default useFilterQueryParams;
