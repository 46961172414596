import classes from "./Search.module.scss";
import magnifierIcon from "../../../assets/images/icons/magnifier-icon.svg";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import React from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { SearchRef } from "../filter-component";
interface SearchProps {
  onChange: (query: string) => void;
  debounceTime?: number;
}
const Search = forwardRef<SearchRef, SearchProps>((props: SearchProps, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  let debounceTimer: NodeJS.Timeout;

  const componentRef = useClickAway<any>(() => {
    if (isVisible) {
      handleSearchVisibility(false);
    }
  });

  const handleSearchVisibility = (isVisible: boolean) => {
    // don't close if there is a value
    if (
      !isVisible &&
      inputRef.current?.value &&
      inputRef.current.value.length > 0
    ) {
      return;
    }

    setIsVisible(isVisible);
  };

  useImperativeHandle(ref, () => ({
    reset() {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
    close() {
      setIsVisible(false);
    },
    setValue(query: string) {
      if (inputRef.current && query.length > 0) {
        setIsVisible(true);
        inputRef.current.value = query;
      }
    },
  }));

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const debounceTime = props.debounceTime || 0;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      props.onChange(event.target.value);
    }, debounceTime);
  };

  return (
    <div
      ref={componentRef}
      className={`square-button ${classes.wrapper} ${
        isVisible ? classes.opened : ""
      }`}
      onClick={() => {
        handleSearchVisibility(!isVisible);
      }}
    >
      <img className={classes.icon} src={magnifierIcon} alt="magnifier icon" />
      <input
        type="text"
        className="filter-text"
        placeholder="Vyhľadajte službu..."
        ref={inputRef}
        onChange={handleChange}
      />
    </div>
  );
});
export default Search;
