import { ColorScheme } from "../footer/Footer";

export enum Icon {
  FB,
  IG,
  MAIL,
  PHONE,
}

export interface IconProps {
  style: ColorScheme;
  icon: Icon;
}

const createFacebookIcon = (style: ColorScheme) => {
  return (
    <svg viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.6772 0H4.10577C3.25328 0 2.43572 0.338647 1.83292 0.941443C1.23013 1.54424 0.891479 2.3618 0.891479 3.21429L0.891479 26.7857C0.891479 27.6382 1.23013 28.4558 1.83292 29.0586C2.43572 29.6614 3.25328 30 4.10577 30H13.2966V19.8007H9.07786V15H13.2966V11.3411C13.2966 7.17924 15.7743 4.88036 19.5692 4.88036C21.3866 4.88036 23.287 5.20446 23.287 5.20446V9.28929H21.193C19.1299 9.28929 18.4863 10.5696 18.4863 11.8828V15H23.0921L22.3555 19.8007H18.4863V30H27.6772C28.5297 30 29.3472 29.6614 29.95 29.0586C30.5528 28.4558 30.8915 27.6382 30.8915 26.7857V3.21429C30.8915 2.3618 30.5528 1.54424 29.95 0.941443C29.3472 0.338647 28.5297 0 27.6772 0Z"
        fill={
          style === ColorScheme.DARK
            ? "var(--light-text-color)"
            : "var(--dark-text-color)"
        }
      />
    </svg>
  );
};
const createInstagramIcon = (style: ColorScheme) => {
  return (
    <svg viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8948 7.30838C11.6383 7.30838 8.20492 10.7425 8.20492 15C8.20492 19.2575 11.6383 22.6916 15.8948 22.6916C20.1514 22.6916 23.5847 19.2575 23.5847 15C23.5847 10.7425 20.1514 7.30838 15.8948 7.30838ZM15.8948 20.0006C13.1441 20.0006 10.8954 17.758 10.8954 15C10.8954 12.242 13.1374 9.99944 15.8948 9.99944C18.6522 9.99944 20.8943 12.242 20.8943 15C20.8943 17.758 18.6455 20.0006 15.8948 20.0006ZM25.6929 6.99375C25.6929 7.99119 24.8898 8.7878 23.8993 8.7878C22.9021 8.7878 22.1056 7.98449 22.1056 6.99375C22.1056 6.00301 22.9088 5.19971 23.8993 5.19971C24.8898 5.19971 25.6929 6.00301 25.6929 6.99375ZM30.7861 8.81457C30.6723 6.41136 30.1235 4.28261 28.3633 2.52873C26.6098 0.774852 24.4816 0.225929 22.0789 0.105433C19.6026 -0.0351445 12.1804 -0.0351445 9.70408 0.105433C7.3081 0.219235 5.17982 0.768158 3.41964 2.52203C1.65947 4.27591 1.11736 6.40466 0.996889 8.80788C0.856343 11.2847 0.856343 18.7086 0.996889 21.1854C1.11067 23.5886 1.65947 25.7174 3.41964 27.4713C5.17982 29.2251 7.30141 29.7741 9.70408 29.8946C12.1804 30.0351 19.6026 30.0351 22.0789 29.8946C24.4816 29.7808 26.6098 29.2318 28.3633 27.4713C30.1168 25.7174 30.6656 23.5886 30.7861 21.1854C30.9266 18.7086 30.9266 11.2914 30.7861 8.81457ZM27.587 23.843C27.0649 25.1551 26.0543 26.1659 24.7359 26.6947C22.7615 27.478 18.0766 27.2972 15.8948 27.2972C13.713 27.2972 9.02143 27.4713 7.05378 26.6947C5.74201 26.1726 4.73141 25.1618 4.20269 23.843C3.41964 21.8682 3.60035 17.1823 3.60035 15C3.60035 12.8177 3.42634 8.12507 4.20269 6.15698C4.72472 4.84492 5.73532 3.8341 7.05378 3.30525C9.02812 2.52203 13.713 2.70278 15.8948 2.70278C18.0766 2.70278 22.7682 2.52873 24.7359 3.30525C26.0476 3.8274 27.0582 4.83822 27.587 6.15698C28.37 8.13176 28.1893 12.8177 28.1893 15C28.1893 17.1823 28.37 21.8749 27.587 23.843Z"
        fill={
          style === ColorScheme.DARK
            ? "var(--light-text-color)"
            : "var(--dark-text-color)"
        }
      />
    </svg>
  );
};

const createMailIcon = (style: ColorScheme) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 13.5001H21C21.5525 13.5001 22 13.0526 22 12.5001V11.5001C22 10.9476 21.5525 10.5001 21 10.5001H11C10.4475 10.5001 10 10.9476 10 11.5001V12.5001C10 13.0526 10.4475 13.5001 11 13.5001ZM10 18.5001C10 19.0526 10.4475 19.5001 11 19.5001H21C21.5525 19.5001 22 19.0526 22 18.5001V17.5001C22 16.9476 21.5525 16.5001 21 16.5001H11C10.4475 16.5001 10 16.9476 10 17.5001V18.5001ZM16 26.0707C14.9738 26.0707 13.9475 25.7544 13.0712 25.1213L0 15.6788V29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V15.6788L18.9287 25.1213C18.0525 25.7538 17.0263 26.0707 16 26.0707ZM30.8506 10.1845C30.2975 9.75076 29.7731 9.34264 29 8.75889V6.00016C29 4.34329 27.6569 3.00017 26 3.00017H21.1531C20.9631 2.86268 20.7863 2.73393 20.5881 2.59018C19.5375 1.82331 17.45 -0.021682 16 0.000192834C14.55 -0.021682 12.4631 1.82331 11.4119 2.59018C11.2137 2.73393 11.0369 2.86268 10.8469 3.00017H6C4.34312 3.00017 3 4.34329 3 6.00016V8.75889C2.22688 9.34201 1.7025 9.75076 1.14937 10.1845C0.791349 10.4651 0.501822 10.8234 0.302696 11.2324C0.10357 11.6413 6.16129e-05 12.0902 0 12.5451L0 13.2107L6 17.5451V6.00016H26V17.5451L32 13.2107V12.5451C32 11.6239 31.5762 10.7533 30.8506 10.1845Z"
        fill={
          style === ColorScheme.DARK
            ? "var(--light-text-color)"
            : "var(--dark-text-color)"
        }
      />
    </svg>
  );
};

const createPhoneIcon = (style: ColorScheme) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5714 0H3.42857C2.51926 0 1.64719 0.361224 1.00421 1.00421C0.361223 1.64719 0 2.51926 0 3.42857L0 28.5714C0 29.4807 0.361223 30.3528 1.00421 30.9958C1.64719 31.6388 2.51926 32 3.42857 32H28.5714C29.4807 32 30.3528 31.6388 30.9958 30.9958C31.6388 30.3528 32 29.4807 32 28.5714V3.42857C32 2.51926 31.6388 1.64719 30.9958 1.00421C30.3528 0.361224 29.4807 0 28.5714 0ZM27.4007 21.955L26.3293 26.5979C26.2749 26.8339 26.1421 27.0445 25.9525 27.1954C25.763 27.3463 25.528 27.4285 25.2857 27.4286C13.8571 27.4286 4.57143 18.1636 4.57143 6.71429C4.57993 6.47433 4.66523 6.24343 4.81477 6.05557C4.96431 5.86771 5.1702 5.73281 5.40214 5.67071L10.045 4.59929C10.1242 4.58254 10.2048 4.57321 10.2857 4.57143C10.4929 4.58189 10.6935 4.64762 10.8667 4.76179C11.0399 4.87597 11.1794 5.03442 11.2707 5.22071L13.4136 10.2207C13.4661 10.3555 13.4953 10.4983 13.5 10.6429C13.4826 10.96 13.3415 11.2577 13.1071 11.4721L10.4007 13.6864C12.0406 17.1621 14.8379 19.9594 18.3136 21.5993L20.5279 18.8929C20.7423 18.6585 21.04 18.5174 21.3571 18.5C21.5017 18.5047 21.6445 18.5339 21.7793 18.5864L26.7793 20.7293C26.9657 20.8205 27.1242 20.9599 27.2384 21.1331C27.3526 21.3064 27.4183 21.5071 27.4286 21.7143C27.4271 21.7952 27.4178 21.8759 27.4007 21.955Z"
        fill={
          style === ColorScheme.DARK
            ? "var(--light-text-color)"
            : "var(--dark-text-color)"
        }
      />
    </svg>
  );
};
const getIcon = (props: IconProps) => {
  switch (props.icon) {
    case Icon.FB:
      return createFacebookIcon(props.style);
    case Icon.IG:
      return createInstagramIcon(props.style);
    case Icon.MAIL:
      return createMailIcon(props.style);
    case Icon.PHONE:
      return createPhoneIcon(props.style);
  }
};

const Icons = (props: IconProps) => {
  return getIcon(props);
};
export default Icons;
