import classes from "./PriorityCard.module.scss";
import { useDimensions } from "../../hooks/use-dimensions";
import { useEffect, useRef } from "react";

interface PriorityCardProps {
  cardIcon: any;
  text: string;
}
const PriorityCard = (props: PriorityCardProps) => {
  const { startObserver, dimensions, disconnect } =
    useDimensions("--icon-height");
  const iconHolder = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    startObserver(iconHolder.current);
    return () => {
      disconnect();
    };
  }, []);

  return (
    <div className={classes["priority-card"]}>
      <div className={classes["icon-holder"]} ref={iconHolder}>
        <img src={props.cardIcon} alt="card icon" />
      </div>
      <div className={classes["card-body"]}>
        <p>{props.text}</p>
      </div>
    </div>
  );
};
export default PriorityCard;
