import { Category } from "../../../api/models/category";
import classes from "./Forms.module.scss";
import Dropdown, {
  DropdownData,
} from "../../filter-components/dropdown/Dropdown";
import BasicInput from "../../inputs/basic-input/BasicInput";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormValues } from "./form-data";
import { AnimatePresence, motion } from "framer-motion";
import { FormProps } from "./form-props";
import { CategoriesAPI } from "../../../api/services/CategoriesAPI";
import { DropdownRef } from "../../filter-components/filter-component";

export interface CategoryModalRef {
  reset: () => void;
}

const EditDeleteCategory = forwardRef<CategoryModalRef, FormProps>(
  (props, ref) => {
    const [isValid, setIsValid] = useState(false);
    const [formData, setFormData] = useState<FormValues>({});
    const [categoriesArray, setCategoriesArray] = useState([] as Category[]);
    const dropdownRef = useRef<DropdownRef>(null);

    useImperativeHandle(ref, () => ({
      reset() {
        setFormData({});
        fetchCategories();
        setIsValid(false);
        dropdownRef.current?.reset();
      },
    }));
    const handleSelection = (item?: DropdownData) => {
      setFormData((prevState) => ({
        ...prevState,
        category_id: item?.id || null,
        name: item?.title || null,
      }));
    };

    const handleChange = (value: string) => {
      setFormData((prevState) => ({
        ...prevState,
        name: value,
      }));
    };

    const checkValidity = () => {
      if (
        formData.category_id &&
        formData.name &&
        formData.name !==
          categoriesArray.find(
            (item) => item.category_id === formData.category_id
          )?.name
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };

    const fetchCategories = () => {
      CategoriesAPI.getAll().then((data) => {
        setCategoriesArray(data);
      });
    };

    useEffect(() => {
      props.onFormValueChange(formData);
      checkValidity();
    }, [formData]);

    useEffect(() => {
      props.onFormValidityChange(isValid);
    }, [isValid]);

    // data fetching
    useEffect(() => {
      fetchCategories();
    }, []);

    return (
      <form className={`${classes.form} ${classes["edit-delete-category"]}`}>
        <Dropdown
          data={categoriesArray.map((item) => ({
            id: item.category_id,
            title: item.name,
          }))}
          ref={dropdownRef}
          isModalDropdown={true}
          placeholder={"Vyberte kategóriu"}
          onItemSelection={handleSelection}
        />
        <AnimatePresence>
          {formData.category_id && (
            <motion.div
              className={classes["input-wrapper"]}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "fit-content" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <BasicInput
                placeholder={"Názov kategórie"}
                onChange={handleChange}
                required={true}
                value={formData.name}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    );
  }
);
export default EditDeleteCategory;
