import axios from "../configs/api-config";
import { Day } from "../models/day";
export const DaysAPI = {
  getAll: async () => {
    const { data } = await axios.request({
      method: "GET",
      url: "/days",
    });
    return data as Day[];
  },
};
