import { useCallback, useRef, useState } from "react";
import { ElementDimensions } from "../api/models/element-dimensions";

const STARTING_DIMENSIONS: ElementDimensions = {
  width: 0,
  height: 0,
};
export const useDimensions = (
  cssHeightName?: string,
  cssWidthName?: string
) => {
  const [elementDimensions, setElementDimensions] =
    useState<ElementDimensions>(STARTING_DIMENSIONS);
  const observedElement = useRef<HTMLElement | null>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const setProperties = useCallback(
    (elementDimensions: ElementDimensions) => {
      if (cssWidthName) {
        document.documentElement.style.setProperty(
          cssWidthName,
          `${elementDimensions.width}px`
        );
      }
      if (cssHeightName) {
        document.documentElement.style.setProperty(
          cssHeightName,
          `${elementDimensions.height}px`
        );
      }
    },
    [cssWidthName, cssHeightName]
  );

  const checkDimensions = useCallback(() => {
    if (observedElement.current) {
      const width = observedElement.current.offsetWidth;
      const height = observedElement.current.offsetHeight;

      setElementDimensions({
        width,
        height,
      });

      if (cssWidthName || cssHeightName) {
        setProperties({ width, height });
      }
    }
  }, [cssWidthName, cssHeightName, setProperties]);

  const startObserver = useCallback(
    (el: HTMLElement | null) => {
      if (observedElement.current && observer.current) {
        observer.current.unobserve(observedElement.current);
      }
      if (el) {
        observedElement.current = el;
        observer.current = new ResizeObserver(checkDimensions);
        observer.current.observe(el);
      }
    },
    [checkDimensions]
  );

  const disconnect = useCallback(() => {
    if (observedElement.current && observer.current) {
      observer.current.unobserve(observedElement.current);
      observer.current = null;
      setProperties(STARTING_DIMENSIONS);
    }
  }, [setProperties]);

  return {
    startObserver,
    dimensions: elementDimensions,
    disconnect,
  };
};
