import { createSlice } from "@reduxjs/toolkit";
import { AdminModalType } from "../components/admin-modals/admin-modal-type";
import { Coach } from "../api/models/coach";
import { Service } from "../api/models/service";
import { CategoryEditInput } from "../api/models/category-edit-input";

interface AdminData {
  isAdmin: boolean;
  modalOptions: {
    modalType: AdminModalType;
    modalOpened: boolean;
    modalData: Coach | Service | CategoryEditInput | null;
  };
}

const initialState: AdminData = {
  isAdmin: false,
  modalOptions: {
    modalType: AdminModalType.ADD_SERVICE,
    modalOpened: false,
    modalData: null,
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setIsAdmin(state, action) {
      state.isAdmin = action.payload.isAdmin;
    },
    openModal(state, action) {
      state.modalOptions.modalType = action.payload.modalType;
      state.modalOptions.modalData = action.payload.modalData;
      state.modalOptions.modalOpened = true;
    },
    closeModal(state) {
      state.modalOptions.modalOpened = false;
      state.modalOptions.modalData = null;
    },
  },
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
